import { Company, SortableCompanyTransactionColumns } from "company/types";
import { useState, useEffect } from "react";
import {
    TransactionFilters,
    TransactionType,
} from "company/routes/Transactions/hooks/useTransactionTable";
import { getCompanyTransfers } from "api";
import { useAuthQuery } from "hooks/useAuthQuery";
import { useUser } from "context/User";
import { useEns } from "context/EnsProvider";
import { QueryKey } from "api/types";

interface GetCompanyTransfersProps {
    id?: string;
    type?: TransactionType;
    filters?: TransactionFilters;
    sortBy?: SortableCompanyTransactionColumns | undefined;
    sortDir?: "asc" | "desc" | undefined;
    page?: number;
    limit?: number;
}

export const useGetCompanyTransfers = (props?: GetCompanyTransfersProps) => {
    const { id, type, filters, sortBy, sortDir, page, limit } = props || {};

    const { getEntityId, getSessionToken } = useUser();
    const { lookupEnsRecords } = useEns();

    const [transactions, setTransactions] = useState<Company.Transaction[]>([]);
    const [totalResults, setTotalResults] = useState<number>();

    const queryKey = [
        QueryKey.CompanyTransfers,
        { page, limit, sortBy, sortDir, id, type, ...filters },
    ];

    const {
        data: getCompanyTransfersData,
        isError: getCompanyTransfersIsError,
        isLoading: getCompanyTransfersIsLoading,
        isSuccess: getCompanyTransfersIsSuccess,
        dataUpdatedAt: getCompanyTransfersDataUpdatedAt,
        refetch: getCompanyTransfersRefetch,
        isFetching: getCompanyTransfersIsFetching,
    } = useAuthQuery({
        queryKey,
        queryFn: () => {
            return getCompanyTransfers(
                getEntityId(),
                { page, limit, sortBy, sortDir, id, type, ...filters },
                { Authorization: getSessionToken() }
            );
        },
    });

    useEffect(() => {
        setTransactions(getCompanyTransfersData?.transactions || []);
        setTotalResults(getCompanyTransfersData?.totalResults || 0);

        // Find and format wallet addresses from Contract[] objects
        const transfersAddresses =
            getCompanyTransfersData?.transactions.reduce<string[]>(
                (addresses, { receiver }) => {
                    addresses.push(receiver.wallet);
                    return addresses;
                },
                []
            ) || [];
        lookupEnsRecords(transfersAddresses);
    }, [getCompanyTransfersData, lookupEnsRecords]);

    return {
        totalResults,
        transactions,
        getCompanyTransfersIsError,
        getCompanyTransfersIsLoading,
        getCompanyTransfersIsSuccess,
        getCompanyTransfersDataUpdatedAt,
        getCompanyTransfersRefetch,
        getCompanyTransfersIsFetching,
    };
};
