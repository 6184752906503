import React, { useCallback, useState, useRef } from "react";
import { Company, UpdateTransfer } from "company/types";
import * as S from "./style";
import Button, { ButtonVariants } from "components/Button";
import { useNotificationQueue } from "context/NotificationQueue";
import TransferNotesField, {
    TransferNotesFieldRef,
} from "company/components/transfers/TransferNotesField";
import { patchCompanyTransfers } from "api";
import { NotificationType } from "components/Notification";
import { useUser } from "context/User";
import { QueryKey } from "api/types";
import useInvalidateQuery from "hooks/useInvalidateQuery";

interface UpdateTransferNotesFormProps {
    transaction: Company.Transaction;
    onCancel?: () => void;
    onSuccess?: () => void;
}

const UpdateTransferNotesForm: React.FunctionComponent<
    UpdateTransferNotesFormProps
> = ({ transaction, onCancel, onSuccess }) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);

    // Session & Company data                          ID SHOULD BE IN THERE
    const { invalidateQueries } = useInvalidateQuery();
    const { getEntityId, getSessionToken } = useUser();

    // Notifications
    const { addNotification } = useNotificationQueue();

    const transferNotesFieldRef = useRef<TransferNotesFieldRef>(null);

    const successHandle = useCallback(async () => {
        await invalidateQueries(QueryKey.CompanyTransfers);
        setFormLoading(false);
        if (onSuccess) onSuccess();
    }, [invalidateQueries, onSuccess]);

    const updateNotes = async () => {
        setFormLoading(true);

        // Get & Validate notes
        let notes: string | undefined;
        if (transferNotesFieldRef.current) {
            notes = transferNotesFieldRef.current.notes;
            if (!transferNotesFieldRef.current.validate()) return;

            // if data hasn't change, trigger onSuccess
            if (!transferNotesFieldRef.current.hasChanged) {
                if (onSuccess) onSuccess();
                return;
            }
        }

        if (!notes) return;

        const patchTransfer: UpdateTransfer = {
            transferId: transaction.transferId,
            notes,
        };

        const headers = {
            Authorization: getSessionToken(),
            "entity-id": getEntityId(),
        };

        const { response } = await patchCompanyTransfers(
            [patchTransfer],
            headers
        );

        const successFullResponse = response.ok && response.status === 200;

        if (successFullResponse) {
            await successHandle();
        } else {
            addNotification({
                msg: `There was an error while updating the notes`,
                type: NotificationType.ERROR,
            });
        }

        setFormLoading(false);
    };

    return (
        <S.EditForm onSubmit={updateNotes}>
            <TransferNotesField
                ref={transferNotesFieldRef}
                defaultNotes={transaction.notes || ""}
                disabled={formLoading}
            />
            <Button type="submit" loading={formLoading}>
                {formLoading ? "Saving" : "Save"}
            </Button>
            <Button
                variant={ButtonVariants.Anchor}
                type="button"
                disabled={formLoading}
                onClick={onCancel}
            >
                Cancel
            </Button>
        </S.EditForm>
    );
};

export default UpdateTransferNotesForm;
