import * as S from "./style";
import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import { TooltipProps } from "@mui/material/Tooltip";
import {
    getBlockExplorerUrl,
    BlockExplorerEntityType,
    GetBlockExplorerUrlProps,
} from "utils/urls";
import {
    getEvmAddress,
    isEvmAddress,
    isEvmTxHash,
    isSolTxHash,
    shortenAddress,
} from "utils/addresses";
import Tooltip from "components/Tooltip";
import Copyable from "components/Copyable";
import Anchor from "components/Anchor";

export type BaseProps = {
    children?: ReactNode;
    address: string;
    ens?: string;
    networkId?: string;
    shorten?: boolean;
    tooltipProps?: TooltipProps;
    icon?: boolean;
    iconFill?: string;
    underlined?: boolean;
    className?: string;
    style?: Partial<CSSProperties>;
    nowrap?: boolean;
    inheritColor?: boolean;
    anchor?: boolean;
    monospace?: boolean;
    placement?: "left" | "right" | "top" | "bottom";
    onClick?: MouseEventHandler<HTMLAnchorElement>;
};

// Default props if type is not SafeTx
export type DynamicAddressDisplayProps = BaseProps &
    (
        | {
              type: BlockExplorerEntityType.SafeTx;
              safeAddress: string;
          }
        | {
              type?: Exclude<
                  BlockExplorerEntityType,
                  BlockExplorerEntityType.SafeTx
              >;
              safeAddress?: string;
          }
    );

const DynamicAddressDisplay = ({
    children,
    address,
    ens,
    networkId,
    type,
    shorten = false,
    tooltipProps,
    icon = false,
    iconFill = ``,
    underlined = true,
    className,
    style,
    nowrap = false,
    inheritColor = true,
    anchor = true,
    monospace = false,
    placement = "left",
    safeAddress,
    onClick,
}: DynamicAddressDisplayProps) => {
    type =
        type ??
        (isEvmTxHash(address) || isSolTxHash(address)
            ? BlockExplorerEntityType.Transaction
            : BlockExplorerEntityType.Address);

    if (type === BlockExplorerEntityType.SafeTx) {
        if (!safeAddress) {
            throw new Error(
                "`safeAddress` must be defined when `type` is `SafeTx`"
            );
        }
    }

    const addressBlockExplorerUrl = networkId
        ? getBlockExplorerUrl({
              address,
              type,
              networkId,
              safeAddress,
          } as GetBlockExplorerUrlProps)
        : null;

    // Checksum addresses if available
    if (isEvmAddress(address)) address = getEvmAddress(address);
    if (typeof children === `string` && isEvmAddress(address))
        children = getEvmAddress(children);

    const displayAddress =
        children || (shorten ? shortenAddress(ens || address) : ens || address);

    return (
        <Tooltip
            title={
                <Copyable value={address} fill="white">
                    {address}
                </Copyable>
            }
            placement={placement}
            noWrap
            open={shorten ? undefined : false}
            {...tooltipProps}
        >
            {addressBlockExplorerUrl && anchor ? (
                <S.Address style={{ color: `inherit` }} monospace={monospace}>
                    <Anchor
                        href={addressBlockExplorerUrl}
                        inheritColor={inheritColor}
                        icon={icon}
                        iconFill={iconFill}
                        underlined={underlined}
                        className={className}
                        nowrap={nowrap}
                        style={style}
                        onClick={onClick}
                    >
                        {displayAddress}
                    </Anchor>
                </S.Address>
            ) : (
                <S.Address
                    monospace={monospace}
                    className={className}
                    style={style}
                    onClick={onClick}
                >
                    {displayAddress}
                </S.Address>
            )}
        </Tooltip>
    );
};

export default DynamicAddressDisplay;
