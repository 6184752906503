import { PagingQueryParams } from "api/types";

export enum InvoicePaymenTableColumns {
    datePaid = "datePaid",
    invoiceId = "invoiceId",
    from = "from",
    amount = "amount",
    network = "network",
    status = "status",
}

export type InvoicePaymentHeadings = {
    [K in InvoicePaymenTableColumns]: Field;
};

export interface InvoiceDataLoader {
    sort: SortBy;
    pagination: Pagination;
}

export interface GetInvoicePaymentsHttpRequest
    extends PagingQueryParams<string> {}
