import { formatUnits, parseUnits } from "ethers";
import { PERCENTAGE_SCALE_FACTOR } from "default-variables";
import {
    AmountType,
    CompanyEntityFeeResponse,
    FeeConfigurationType,
} from "company/types";
import { convertCentsToTokenBN } from "utils/exchangeRates";
import { isOutboundPayment } from "utils/items";
import { ItemCategoryType } from "types/common-enums";

export const getApplicableFee = (
    types: ItemCategoryType[],
    fees: CompanyEntityFeeResponse[],
    ntwrkId: number
) => {
    // Only outbound payments fees are shown for now
    if (!types.every((type) => isOutboundPayment(type))) return;
    return fees.find(
        ({ type, networkId }) =>
            type === FeeConfigurationType.Outbound && networkId === ntwrkId
    );
};

// Returns the total payment amount and fee amount, in the same decimal format (of the token)
export const calculateAmountAndFee = ({
    fee,
    amount,
    usd = true,
    decimals = 0,
}: {
    fee: CompanyEntityFeeResponse | undefined;
    amount: string | null | undefined;
    decimals: number | undefined;
    usd?: boolean;
}) => {
    let paymentTotal = BigInt(0);
    let feeTotal = BigInt(0);

    if (amount && parseFloat(amount) >= 0) {
        paymentTotal =
            paymentTotal +
            (usd
                ? parseUnits(parseFloat(amount).toFixed(decimals), decimals)
                : BigInt(amount));
    }

    if (fee) {
        const feeAmount = fee.amount || 0;
        const feeType = fee.amountType as AmountType;

        feeTotal =
            feeType === AmountType.Fiat
                ? feeTotal + parseUnits(String(feeAmount), decimals)
                : feeType === AmountType.Percent
                  ? (paymentTotal * BigInt(feeAmount)) /
                    BigInt(PERCENTAGE_SCALE_FACTOR)
                  : BigInt(0);
    }

    return {
        paymentTotal,
        feeTotal,
    };
};

export const formatPaymentAndFees = ({
    fee,
    amount,
    usd = true,
    decimals = 0,
    rate,
    isFiat = false,
}: {
    fee: CompanyEntityFeeResponse | undefined;
    amount: string | null | undefined;
    usd?: boolean;
    decimals: number | undefined;
    rate: number;
    isFiat?: boolean;
}) => {
    const { paymentTotal, feeTotal } = calculateAmountAndFee({
        fee,
        amount,
        decimals,
        usd,
    });

    const feeInCorrectCurrency =
        !usd && isFiat ? convertCentsToTokenBN(feeTotal, rate) : feeTotal;

    const payment = formatUnits(paymentTotal, decimals);

    const paymentFees = formatUnits(feeInCorrectCurrency, decimals);

    const paymentMinusFees = formatUnits(
        paymentTotal - feeInCorrectCurrency,
        decimals
    );

    return {
        payment,
        paymentFees,
        paymentMinusFees,
    };
};
