import React, { useCallback, useRef } from "react";
import { isAbsolutePath } from "utils/urls";
import {
    Button as StyledButton,
    ButtonNoStyles,
    AnchorButton,
    LinkButton,
    ButtonSpinner,
} from "./style";

export enum ButtonVariants {
    Primary = "primary",
    PrimaryOutlined = "primaryOutlined",
    PrimaryBorderless = "primaryBorderless",
    Neutral = "neutral",
    NeutralOutlined = "neutralOutlined",
    Anchor = "anchor",
}

export enum ButtonSizes {
    Small = "small",
    Medium = "medium",
    Large = "large",
}

interface SharedButtonProps {
    variant?: ButtonVariants;
    size?: ButtonSizes;
    full?: boolean;
    loading?: boolean;
    noStyles?: boolean; // this will ignore variant, size, etc.. and simply output a button (for semantic purpose)
    anchorVariantUnderlined?: boolean; // TODO: This should use Anchor with button mode
}

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        SharedButtonProps {
    href?: string;
    target?: string;
    download?: string;
    onClick?: (event?: any) => void;
}

const debounceDelay = 200;

export default function Button({
    children,
    variant = ButtonVariants.Primary,
    size = ButtonSizes.Medium,
    full = false,
    disabled = false,
    noStyles = false,
    loading = false,
    type, // = "button", // [ ] This may break <forms> that have buttons in them, without "submit" designation, but should be changed
    href,
    target,
    download,
    onClick,
    anchorVariantUnderlined = true,
    ...props
}: ButtonProps) {
    const debounceRef = useRef<NodeJS.Timeout | null>(null);

    const handleDebouncedClick = useCallback(
        (event?: any) => {
            if (debounceRef.current || !onClick) {
                return;
            }

            onClick(event);

            debounceRef.current = setTimeout(() => {
                debounceRef.current = null;
            }, debounceDelay);
        },
        [onClick]
    );

    // A. Unstyled <button> (for semantic purposes)
    if (noStyles) {
        return (
            <ButtonNoStyles
                disabled={disabled}
                type={type}
                onClick={handleDebouncedClick}
                {...props}
            >
                {children}
            </ButtonNoStyles>
        );
    }

    const sharedProps = {
        onClick: handleDebouncedClick,
        $variant: variant,
        $size: size,
        $full: full,
        $disabled: disabled,
        $loading: loading,
        $anchorVariantUnderlined: anchorVariantUnderlined,
    };

    const buttonChildren = (
        <>
            {loading && <ButtonSpinner color="currentColor" />}
            {children}
        </>
    );

    // B. Link that looks like a button
    if (href) {
        const linkProps = {
            target: target,
            download: download,
            ...sharedProps,
        };
        const isExternalLink =
            isAbsolutePath(href) || target === "_blank" || download;

        if (isExternalLink) {
            // B.1. Regular link
            return (
                <AnchorButton {...linkProps} href={href}>
                    {buttonChildren}
                </AnchorButton>
            );
        } else {
            // B.2. react-router-dom Smart link
            return (
                <LinkButton {...linkProps} to={href}>
                    {buttonChildren}
                </LinkButton>
            );
        }
    }

    // C. A good ol <button>
    return (
        <StyledButton
            disabled={disabled}
            type={type}
            {...sharedProps}
            {...props}
        >
            {buttonChildren}
        </StyledButton>
    );
}
