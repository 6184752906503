import {
    link,
    highlight,
    colorsDeprecated as c,
    properties as p,
} from "global-style";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { fontSizes } from "theme/typography";
import { svgElementToCssString } from "utils/components";
import Anchor from "components/Anchor";
import CarretDown from "components/icons/CarretDown";

export const Menu = styled.section<{ theme?: string }>`
    width: 100%;
    ${({ theme }) => {
        if (theme === "dark") {
            const carretIcon = svgElementToCssString(
                <CarretDown fill="#fff" />
            );
            return css`
                --carret-icon-url: url("${carretIcon}");
            `;
        } else {
            const carretIcon = svgElementToCssString(<CarretDown />);
            return css`
                --carret-icon-url: url("${carretIcon}");
                & ${DynamicSubNav} {
                    border-color: ${c.lighterOnSurface};
                }
            `;
        }
    }}
`;

export const SectionHeading = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    margin-block: 1rem;
`;

export const List = styled.ul`
    margin-block: 1.5rem;
`;

export const Item = styled.li`
    margin-block: 1rem;
    width: 100%;
`;

export const DynamicSubNav = styled.ul<{ selected: boolean }>`
    font-size: ${fontSizes.sm};
    margin-block-start: 0.5rem;
    margin-inline-start: 0.125rem;
    padding-inline-start: 1rem;
    border-inline-start: 0.1rem solid #ddd;
    display: ${({ selected }: { selected: boolean }) =>
        selected ? "block" : "none"};

    & li {
        margin-block: 0 0.5rem;
    }
`;

export const InternalLink = styled(NavLink)<{ disabled?: boolean }>`
    ${link}
    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            color: ${c.disabledInverse};
            fill: ${c.disabledInverse};
        `}

    &:has(+ ${DynamicSubNav}) {
        position: relative;
        padding-inline-end: 1em;
        width: 100%;
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 0.75em;
            height: 0.75em;
            background-image: var(--carret-icon-url);
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    &.active,
    &:has(+ ${DynamicSubNav}[aria-expanded="true"]) {
        ${highlight}
        &::after {
            display: none;
        }
    }
    &:hover {
        ${highlight}
    }
`;

export const StaticLink = styled(Anchor)`
    ${link}
    &:hover {
        ${highlight}
    }
`;

export const CropText = styled.span`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: clip;
    width: max-content;
    max-width: 100%;
    align-items: center;
    gap: 1rem;
    & > svg {
        width: 1.25rem;
        height: 1.25rem;
        vertical-align: text-bottom;
        margin-right: 1rem;
        margin-bottom: 0.0875rem;
    }
`;
