import { SubSection } from "components/SubSection/style";
import styled, { css } from "styled-components";
import spacing, { SpacingTrack } from "theme/spacing";
import { withSpacing } from "utils/styling";

export const Section = styled.section<{
    $narrow: boolean;
    spacing?: SpacingTrack;
}>`
    ${({ spacing }) => withSpacing(spacing)}
    ${({ $narrow }) =>
        $narrow &&
        css`
            max-width: 40rem;
        `};
    ${SubSection} {
        &:not(:last-child) {
            margin-bottom: ${spacing.lg};
        }
    }
`;

export const TitleWrapper = styled.header`
    margin-bottom: ${spacing.sm};
`;
