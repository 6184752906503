import { createElement, Fragment } from "react";
import { distance } from "fastest-levenshtein";
import { onlyUnique } from "utils/arrays";

const firstToUpper = (str: string, remainingLower = false) => {
    return (
        str.charAt(0).toUpperCase() +
        (remainingLower ? str.slice(1).toLowerCase() : str.slice(1))
    );
};

const firstToLower = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

export enum Transform {
    UPPER = "UPPER",
    LOWER = "LOWER",
}
interface ToSafeStr {
    (
        str: string,
        options?: {
            spaces?: string;
            transform?: Transform;
        }
    ): string;
}
const toSafeStr: ToSafeStr = (str, options) => {
    const spaces = options?.spaces || `-`;
    const transform = options?.transform || undefined;

    let safeStr = str.replace(/[^a-zA-Z0-9\s]/g, ``).replace(/\s+/g, spaces);

    if (transform === Transform.UPPER) {
        safeStr = safeStr.toUpperCase();
    } else if (transform === Transform.LOWER) {
        safeStr = safeStr.toLowerCase();
    }

    return safeStr;
};

const toSentence = (words: string[]): string => {
    if (words.length === 0) return "";
    if (words.length === 1) return words[0];
    if (words.length === 2) return words.join(" & ");

    const allButLast = words.slice(0, -1).join(", ");
    const lastWord = words[words.length - 1];

    return `${allButLast} & ${lastWord}`;
};

const checkLevenshtein = (
    strToCheck: string,
    setToCompare: string[],
    tolerance: number = 2
): string | null => {
    const str = strToCheck.toLowerCase();

    let closest = ``;
    let minDistance = Infinity;

    setToCompare.forEach((comparison) => {
        const levenshtein = distance(str, comparison);
        if (levenshtein < minDistance && levenshtein <= tolerance) {
            minDistance = levenshtein;
            closest = comparison;
        }
    });

    if (closest && closest !== str) return closest;

    return null;
};

const fixArticles = (str: string): string => {
    // Regular expression to match "a" or "A" followed by a space and a word (ignoring punctuation) starting with a vowel
    const regex = /\b(a|A)(?=\W*[aeiouAEIOU])/gi;

    return str.replace(regex, (match) => {
        return match === "A" ? "An" : "an";
    });
};

const ListValuesFromIds = ({
    objects,
    pks,
    matchProp,
    valueProp,
    conjunction = `and`,
    wrapper,
}: {
    objects: {
        [key: string]: any;
    }[];
    pks: (number | string)[];
    matchProp: string;
    valueProp: string;
    conjunction?: string;
    wrapper?: React.ElementType;
}) => {
    const values = pks
        .filter(onlyUnique)
        .reduce((acc: (string | number)[], pk: number | string) => {
            const obj = objects.find((obj) => obj[matchProp] === pk);
            if (obj) acc.push(obj[valueProp]);
            return acc;
        }, [])
        .reduce(
            (
                acc: React.ReactNode[],
                value: string | number,
                index: number,
                values: (string | number)[]
            ) => {
                const formattedValue =
                    typeof value === `string` ? firstToUpper(value) : value;
                const element = wrapper
                    ? createElement(wrapper, {}, formattedValue)
                    : formattedValue;

                if (index === 0) {
                    acc.push(<Fragment key={index}>{element}</Fragment>);
                } else if (index === values.length - 1) {
                    acc.push(
                        <Fragment key={index}>
                            {" "}
                            {conjunction} {element}
                        </Fragment>
                    );
                } else {
                    acc.push(<Fragment key={index}>, {element}</Fragment>);
                }

                return acc;
            },
            []
        );

    return values.length ? <>{values}</> : null;
};

export {
    firstToUpper,
    firstToLower,
    toSentence,
    toSafeStr,
    checkLevenshtein,
    fixArticles,
    ListValuesFromIds,
};
