import React from "react";

const CarretDown = ({
    name,
    width = `1rem`,
    height = `1rem`,
    fill = `currentColor`,
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            role="img"
            name={name}
            width={width}
            height={height}
            viewBox="0 0 21 13"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
        >
            {name && <title>{name}</title>}
            <path
                d="M2.28489 0.489912L0.514893 2.26991L10.4149 12.1599L20.3149 2.25991L18.5449 0.489912L10.4149 8.61991L2.28489 0.489912Z"
                fill="inherit"
            />
        </svg>
    );
};

export default CarretDown;
