import styled from "styled-components";
import { sizes as s } from "global-style";
import spacing from "theme/spacing";
import { itemSpacing } from "customer/App/style";

export const SubTable = styled.ul`
    display: grid;
    row-gap: ${spacing[itemSpacing]};
    grid-template-columns: 1fr;
    @media (min-width: ${s.sm}) {
        grid-template-columns: minmax(auto, 12rem) auto 1fr;
    }
`;

export const InvoiceTable = styled.ul`
    display: grid;
    row-gap: ${spacing[itemSpacing]};
    grid-template-columns: 1fr;
    @media (min-width: ${s.sm}) {
        grid-template-columns: repeat(3, auto) 1fr;
    }
`;

export const ListRow = styled.li`
    display: grid;
    align-items: center;
    grid-column: 1 / -1;
    grid-template-columns: inherit;
    @supports (grid-template-columns: subgrid) {
        grid-template-columns: subgrid;
    }
    column-gap: ${spacing.lg};
    @media (min-width: ${s.md}) {
        column-gap: ${spacing.xxxl};
    }
`;
