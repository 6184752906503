type ApiRequestError = {
    code?: string;
    message?: string;
};

const errorMessageMap: { [key: string]: string } = {
    "504": `The server was slow to respond, please try again`,
    "404": `The requested page could not be found`,
    invalid_user_password: `The password you provided is incorrect`,
    invalid_reset_password_key: `Your password reset key is invalid`,
    not_verified_user: `You are not a verified user`,
    not_active_user: `This user account has not been activated`,
};

export const defaultErrorMessage = `An unexpected error occurred`;

export const getErrorMessage = (error: unknown): string => {
    if (typeof error !== `object` || error === null) return defaultErrorMessage;

    const { message = ``, code = `` } = error as ApiRequestError;
    const mappedErrorMessage =
        errorMessageMap[message] ?? errorMessageMap[code];

    return mappedErrorMessage ?? defaultErrorMessage;
};

export enum ErrorCode {
    SOL_ALLOWANCE_TIMEDOUT_AND_TOO_LOW = `SOL_ALLOWANCE_TIMEDOUT_AND_TOO_LOW`,
}

export class CodedError extends Error {
    code: string;

    constructor(message: string, code: string) {
        super(message);
        this.code = code;
        Object.setPrototypeOf(this, new.target.prototype);
    }
    hasCode(code: ErrorCode): boolean {
        return this.code === code;
    }
}

export const isErrorCodedAs = (error: unknown, code: ErrorCode): boolean => {
    return error instanceof CodedError && error.hasCode(code);
};
