import * as S from "./style";
import Section from "components/Section";
import Button from "components/Button";
import AuthorizedWalletsTable from "company/components/AuthorizedWalletsTable";
import AuthorizedNewWalletForm from "company/components/AuthorizedNewWalletForm";
import { useUser } from "context/User";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import { isOutboundPayment } from "utils/items";
import {
    ItemType,
    ItemCategoryTypeOutboundKeysAsStrings,
} from "types/common-enums";
import { getValuesFromKeys } from "utils/objects";

const AuthorizedWallets = () => {
    const { tokens, getTokensMetadataIsLoading, getTokensMetadataIsError } =
        useGetTokensMetadata();

    const { networks, getNetworksIsLoading, getNetworksIsError } =
        useGetNetworks();

    const { items, getCompanyItemsIsError, getCompanyItemsIsLoading } =
        useGetCompanyItems();

    const {
        agreements,
        getCompanyAgreementsDataUpdatedAt,
        getCompanyAgreementsIsLoading,
        getCompanyAgreementsIsError,
        getCompanyAgreementsIsFetching,
        getCompanyAgreementsRefetch,
    } = useGetCompanyAgreements({
        itemTypes: getValuesFromKeys(
            ItemType,
            ItemCategoryTypeOutboundKeysAsStrings
        ).join(","),
    });

    // Filter agreements to ensure that the outbound payment items it has are still active
    const outboundPaymentItems = items.filter(
        (item) => isOutboundPayment(item.type) && item.active
    );
    const activeOutboundPaymentAgreements = agreements.filter((agreement) =>
        agreement.items.some((itemId) =>
            outboundPaymentItems.some((item) => item.id === itemId)
        )
    );

    const isLoading =
        getTokensMetadataIsLoading ||
        getNetworksIsLoading ||
        getCompanyItemsIsLoading ||
        getCompanyAgreementsIsLoading;

    const isError =
        getTokensMetadataIsError ||
        getNetworksIsError ||
        getCompanyItemsIsError ||
        getCompanyAgreementsIsError;

    const { isReadOnly } = useUser();

    // This tables relies on agreements, items & config data
    if (isLoading) {
        return (
            <LoadingBox
                placeholderStyle={LoadingPlaceholderStyle.Table}
                tablePlaceholderCols={4}
            />
        );
    }

    if (isError) {
        return <FailedDataFetchingMessage />;
    }

    return (
        <>
            {!isReadOnly() && outboundPaymentItems.length !== 0 && (
                <Section>
                    <S.Filters>
                        <Button href="/pay">Pay</Button>
                        <AuthorizedNewWalletForm />
                    </S.Filters>
                </Section>
            )}
            <Section>
                <RefreshCounter
                    refreshedAt={getCompanyAgreementsDataUpdatedAt}
                    counter={CounterType.Running}
                    onRefresh={getCompanyAgreementsRefetch}
                    refreshing={getCompanyAgreementsIsFetching}
                />
                <AuthorizedWalletsTable
                    items={items}
                    tokens={tokens}
                    networks={networks}
                    agreements={activeOutboundPaymentAgreements}
                    getCompanyAgreementsIsFetching={
                        getCompanyAgreementsIsFetching
                    }
                />
            </Section>
        </>
    );
};

export default AuthorizedWallets;
