import { createGlobalStyle, keyframes, css } from "styled-components";
import colors, { colorsAsHex, getColorVariableName } from "theme/colors";

/**************************************************
   COMMON PROPERTIES
/*************************************************/

export const colorsDeprecated = {
    lightOnSurface: `#555`,
    lighterOnSurface: `#999` /* new, update as needed! */,
    lightestOnSurface: `#c5cad3`,
    lineOnSurface: `#eee`,

    highlightLight: `#ebebeb`,
    onHighlightLight: `#555`,

    primaryLight: `#9a90ff`,
    onPrimaryLight: colors.text,
    primaryLightest: `#d1cfe6`,
    onPrimaryLightest: colors.text,
    primaryDark: `#222b99`,
    onPrimaryDark: colors.white,
    primaryTrans: `#424ff299`,
    onPrimaryTrans: colors.text,
    onPrimaryHover: `#6F7BF9`,

    secondaryLight: `#6ab09a`,
    onSecondaryLight: colors.text,
    secondaryDark: `#3f7765`,
    onSecondaryDark: colors.white,
    secondaryTrans: `#9ceed499`,
    onSecondaryTrans: colors.text,

    tertiary: `#F4DE93`,
    tertiaryLight: `#efce5e`,
    onTertiary: colors.text,
    onTertiaryHover: `#C8FFED`,

    loopColA: `#6ABCFF`,
    loopColB: `#867BF9`,

    modalA: `rgba(0, 0, 20, 0.8)`,
    modalB: `#6633FF`,
    modalC: `#59A2F1`,
    onModalLight: colors.white,
    onModalDark: colors.text,

    shadowLight: `rgba(0, 0, 0, 0.12)`,
    shadowDark: `rgba(0, 0, 0, 0.33)`,
    border: `#ddd`,
    borderHighlight: `#4a3afa`,
    disabled: `rgba(0,0,0,0.1)`,
    onDisabled: `rgba(0,0,0,0.3)`,

    // These are really for "dark mode", which doesn't exist yet in styles :(
    disabledInverse: `rgba(255,255,255,0.7)`,
    onDisabledInverse: `rgba(255,255,255,0.9)`,

    input: "#222",
    inputDisabled: `rgba(0,0,0,0.1)`,
    inputReadonly: `rgba(255,255,255,0.5)`,
    onInputReadonly: `rgba(0,0,0,0.8)`,
    inputPlaceholder: "#aaa",
    inputIndicator: "#5F5D67",
    inputBorder: "#CED4DA",
    label: "#222",
    dropdownItemHover: "#eee",
    bgGradientLight: `linear-gradient(#6bbaff33, #626dff33)`,
    bgGradientDark: `linear-gradient(#4A3AF8, #5AA9F0)`,
    graySection: "#F3EFFA",
    fullPageDropdownBorder: "#c8c5d0",
    popoverCloseLink: "#aaa",

    darken: `rgba(0,0,0,0.1)`,
    lighten: `rgba(255,255,255,0.2)`,
};

export const properties = {
    speed: `0.2s`,
    shadowSm: `${colorsDeprecated.shadowDark} 0 0.125rem 0.25rem`,
    shadowMd: `${colorsDeprecated.shadowLight} 0 0.25rem 0.75rem`,
    shadowLg: `${colorsDeprecated.shadowLight} 0 3rem 6rem -1rem, ${colorsDeprecated.shadowLight} 0 2rem 2.5rem -2rem`,
    small: `0.8em`,
    radiusXl: `2rem`,
    radiusLg: `0.5rem`,
    radiusSm: `0.25rem`,
    focus: `1px solid ${colors.primary}`,
    logoAnimation: `2.5s`,
    roundOffset: `-0.15rem`,
    zIndex: {
        // In-app components (ones/tens)
        tooltips: 1,
        inputIcon: 2,
        selectDropdown: 3,
        navigation: 4,
        wallet: 5,
        dropdown: 6,

        // Modal components (hundreds, w/ ones/tens sub-elements)
        modal: 100,
        loading: 200,
        login: 300,
        manageWallet: 400,
        walletConnect: 401,

        // Notification: above everything
        notifications: 500,
    },
    font: {
        primary: `'Open Sans', 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
        heading: `'Poppins', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
        monospace: `'Roboto Mono', monospace`,
    },
    space: {
        vGutters: {
            xs: `0 1rem`,
            sm: `0 2rem`,
            md: `0 2rem`,
            lg: `0 3rem`,
        },
    },
};

export const sizes = {
    sm: `600px`,
    md: `960px`,
    lg: `1200px`,
    xl: `1440px`,
};

/**************************************************
   CSS SNIPPETS
/*************************************************/

export const iconBg = css`
    background-repeat: no-repeat;
    background-size: 1.5em;
    background-position: calc(100% - 0.5em) center;
    padding-inline-end: 2.5em;
`;

export const layout = css`
    padding: 0 2em;
`;

export const noBlockMargin = css`
    & > :first-child,
    & :first-child:is(h1, h2, h3, h4, h5, h6) {
        margin-block-start: 0;
    }
    & > :last-child {
        margin-block-end: 0;
    }
`;

export const shadowBox = css`
    display: flow-root;
    margin: 1rem 0;
    padding: 1.5rem;
    box-shadow: ${properties.shadowMd};
    border-radius: ${properties.radiusLg};
    background-color: ${colors.white};
    color: ${colors.text};

    ${noBlockMargin}
`;

export const contentBox = css`
    display: flow-root;
    margin: 1rem 0;
    background-color: ${colors.white};
    color: ${colors.text};
    ${noBlockMargin}
`;

export const hideElement = css`
    /* REMEMBER: Parent must be non-static! */
    height: 1px;
    width: 1px;
    padding: 0;
    border: none;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    position: absolute;
`;

export const scrollBar = css`
    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
        background: ${colorsDeprecated.lightestOnSurface};
        border-radius: 0.5rem;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
        margin: 0 0.5rem;
    }
`;

export const link = css`
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    width: max-content;
    max-width: 100%;
`;

export const highlight = css`
    color: ${colors.primary};
    fill: ${colors.primary};
`;

export const commonProperties = ({ center }: { center: boolean }) => css`
    ${center && `text-align: center;`}
`;

/**************************************************
   Global Stylesheet
/*************************************************/

export const GlobalStyle = createGlobalStyle`
    :root {
        ${Object.keys(colorsAsHex).map(
            (colorName) =>
                css`
                    ${getColorVariableName(colorName)}: ${colorsAsHex[
                        colorName
                    ]};
                `
        )}
    }

    *, *:before, *:after {
        box-sizing: border-box;
    }
    html, body {
        height: 100%;
        background: ${colors.neutralBackground};
        color: ${colors.text};
    }
    body {
        display: flow-root;
        margin: 0;
        font-family: ${properties.font.primary};
        font-weight: 400;
        line-height: 1.4;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;

        &.no-scroll {
            overflow: hidden;
        }
    }
    #root {
        min-height: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${properties.font.heading};
      font-weight: 700;
    }
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
        margin: 0.25rem 0;
    }
    h2 {
        font-weight: 600;
        font-size: 1.1rem;
    }
    h3 {
        font-size: 1rem;
    }
    :is(article, aside, nav, section) h1 {
        font-size: 1.5em;
    }
    :is(h1, h2, h3, h4, h5, h6) small {
        font-weight: 400;
    }
    ul {
        list-style: none;
        padding: 0;
    }
    ul.ul {
        list-style-type: disc;
        padding-inline-start: 40px;
    }
    img {
        height: auto;
        max-width: 100%;
        vertical-align: bottom;
    }
    fieldset {
        margin: 0;
        padding: 0;
        border: none;
    }
    input, button, textarea, select {
        font: inherit;
    }
    button {
        border: none;
    }
    code, pre {
        font-size: 1rem;
        background-color: rgba(0,0,0,0.1);
        padding: 0.01rem 0.2rem;
        border-radius: 0.25rem;
    }
    pre {
        padding: 1rem;
    }
    a {
        color: ${colors.primary};
    }
    small {
        font-size: ${properties.small};
    }
    [href]:not([href=""]), [for], [type="button"], [type="submit"], [type="checkbox"], [type="radio"], [type="file"], button {
        cursor: pointer;
    }
    [disabled] {
        cursor: default;
    }
    :focus {
        outline: ${properties.focus};
    }
    ::selection {
        background-color: ${colorsDeprecated.primaryTrans};
        color: ${colorsDeprecated.onPrimaryTrans};
    }
    input:invalid {
      border-color: ${colors.error};
      background-image: url("data:image/svg+xml,%3csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='m6 1 .6-.2h6.8c.3 0 .5.1.6.3L19 6l.2.5v7c0 .2-.1.4-.3.5L14 19l-.6.2H6.6c-.3 0-.5-.1-.6-.3L1 14a.8.8 0 0 1-.2-.6V6.5c0-.2.1-.4.3-.5L6 1Zm.9 1.5L2.5 6.9v6.2l4.4 4.4h6.2l4.4-4.4V6.9l-4.4-4.4H6.9Zm2.3 10.8c0-.4.3-.8.8-.8a.8.8 0 0 1 0 1.7.8.8 0 0 1-.8-.9Zm1.6-6.6a.8.8 0 0 0-1.6 0V10a.8.8 0 0 0 1.6 0V6.7Z' fill='%23FF785A'/%3e%3c/svg%3e");
      ${iconBg}
    }
    input:focus:invalid {
      outline-color: ${colors.error};
    }


    :root {
        accentColor: ${colors.primary};
    }

    :root [data-dynamic-theme] {
        --dynamic-font-family-primary: ${properties.font.primary};
        --dynamic-font-family-numbers: ${properties.font.monospace};
        --dynamic-border-radius: ${properties.radiusLg};
        --dynamic-text-size-button-secondary: 0.875rem;
        --dynamic-text-link: ${colors.primary};
        --dynamic-text-primary: ${colors.text};
        --dynamic-text-secondary: ${colors.text};
        --dynamic-modal-backdrop-background: ${colorsDeprecated.modalA};
        --dynamic-modal-backdrop-filter: none;

        @media screen and (min-width: ${sizes.sm}) {
            --dynamic-modal-width: 28rem;
        }
    }
`;

/**************************************************
   Keyframe Animations
/*************************************************/

export const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
`;

export const fade = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const shrink = keyframes`
    to {
        width: 0;
    }
`;

export const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const shake = keyframes`
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(-0.125rem);
    }
    30% {
        transform: translateX(0.125rem);
    }
    50% {
        transform: translateX(-0.125rem);
    }
    70% {
        transform: translateX(0.125rem);
    }
    90% {
        transform: translateX(-0.125rem);
    }
    100% {
        transform: translateX(0);
    }
`;

export const slide = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;
