import styled from "styled-components";
import { SpacingTrack } from "theme/spacing";
import { withSpacing } from "utils/styling";

export const DescriptionList = styled.dl<{ inline: boolean }>`
    display: ${({ inline }) => (inline ? `inline-block` : `block`)};
`;

export const Row = styled.div<{ spacing?: SpacingTrack }>`
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0.5rem;
    ${({ spacing }) => withSpacing(spacing)}
`;

export const Term = styled.dt<{ separator: string }>`
    justify-self: start;
    ${({ separator }) => separator && `&:after { content: "${separator}"; }`}
`;

export const Definition = styled.dd`
    justify-self: end;
    margin-inline-start: 0;
    text-align: right;
`;
