import * as S from "./style";
import { forwardRef, Ref, useMemo } from "react";
import { formatTokenAndUsd } from "utils/financial";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { useWallet } from "context/Wallet";

interface TokenBalanceDescriptionListRef {}

const TokenBalanceDescriptionList = forwardRef(
    (_, ref: Ref<TokenBalanceDescriptionListRef>) => {
        const { token, tokenBalance, loadingBalance, isTokenPricedCheckout } =
            useCheckoutForm();
        const { walletConnected } = useWallet();

        const items = useMemo(
            () =>
                token
                    ? [
                          {
                              id: `${token.symbol}-balance`,
                              term: `${token.symbol} balance`,
                              definition: loadingBalance
                                  ? `Loading`
                                  : formatTokenAndUsd({
                                        amount: tokenBalance,
                                        symbol: token.symbol,
                                        usdValue: token.exchange.rate,
                                        round: undefined,
                                        hideUsd: isTokenPricedCheckout,
                                    }),
                          },
                      ]
                    : [],
            [token, loadingBalance, tokenBalance, isTokenPricedCheckout]
        );

        if (!walletConnected) return <></>;
        return <S.TokenBalanceDescriptionList items={items} />;
    }
);

export default TokenBalanceDescriptionList;
