import { GeneralTokenDetailsResponse, getTokensMetadata } from "api";
import { useCustomQuery } from "./useCustomQuery";
import { useCallback, useMemo } from "react";
import { QueryKey } from "api/types";

export const useGetTokensMetadata = () => {
    const oneHour = 1000 * 60 * 60;
    const {
        data,
        isError: getTokensMetadataIsError,
        isLoading: getTokensMetadataIsLoading,
        isSuccess: getTokensMetadataIsSuccess,
        isFetching: getTokensMetadataisFetching,
    } = useCustomQuery({
        queryKey: [QueryKey.Tokens],
        queryFn: getTokensMetadata,
        staleTime: oneHour,
    });

    const tokens = useMemo(() => data?.tokens || [], [data?.tokens]);

    const getTokenRate = useCallback(
        (token: GeneralTokenDetailsResponse, currency: string = "USD") => {
            const exchangeRate = token.exchangeRates.find(
                (exchangeRate) => exchangeRate.currency === currency
            );

            return exchangeRate;
        },
        []
    );

    return {
        tokens,
        getTokensMetadataIsError,
        getTokensMetadataIsLoading,
        getTokensMetadataIsSuccess,
        getTokensMetadataisFetching,
        getTokenRate,
    };
};
