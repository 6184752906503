import { getNetworks } from "api";
import { useCustomQuery } from "./useCustomQuery";
import { useMemo } from "react";
import { QueryKey } from "api/types";

export const useGetNetworks = () => {
    const {
        data,
        isError: getNetworksIsError,
        isLoading: getNetworksIsLoading,
        isSuccess: getNetworksIsSuccess,
        isFetching: getNetworksIsFetching,
    } = useCustomQuery({
        queryKey: [QueryKey.Networks],
        queryFn: getNetworks,
    });

    const networks = useMemo(() => data?.networks || [], [data?.networks]);

    return {
        networks: networks,
        getNetworksIsError,
        getNetworksIsLoading,
        getNetworksIsSuccess,
        getNetworksIsFetching,
    };
};
