import { sizes as s } from "global-style";
import styled, { css } from "styled-components";
import colors from "theme/colors";
import spacing from "theme/spacing";
import { itemSpacing } from "customer/App/style";
import Badge from "components/Badge";
import Spinner from "components/Spinner";

export const SubscriptionHeader = styled.div`
    display: grid;
    column-gap: ${spacing.md};
    align-items: start;
    @media (min-width: ${s.sm}) {
        grid-template-columns: 1fr auto;
    }
`;

export const Row = css`
    @media (min-width: ${s.sm}) {
        grid-template-columns: 10rem auto;
    }
    gap: 1rem;
    dt {
        color: ${colors.neutralDark};
    }
    dd {
        justify-self: end;
        @media (min-width: ${s.sm}) {
            justify-self: start;
        }
    }
`;

export const HistoryTable = styled.ul`
    display: grid;
    row-gap: ${spacing[itemSpacing]};
    grid-template-columns: auto;
    @media (min-width: ${s.sm}) {
        grid-template-columns:
            repeat(3, auto)
            1fr;
    }
`;

export const ListRow = styled.li`
    display: grid;
    align-items: center;
    grid-column: 1 / -1;
    grid-template-columns: inherit;
    @supports (grid-template-columns: subgrid) {
        grid-template-columns: subgrid;
    }
    column-gap: ${spacing.lg};
    @media (min-width: ${s.md}) {
        column-gap: ${spacing.xxxl};
    }
`;

export const LowWarning = styled(Badge)`
    margin-inline-start: ${spacing.sm};
`;

export const Loading = styled(Spinner)`
    display: block;
    width: 1.4em;
    height: 1.4rem;
`;
