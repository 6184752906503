import { getCompanyAgreements } from "api";
import { QueryKey } from "api/types";
import { GetCompanyAgreementsHttpRequest } from "company/types";
import { useUser } from "context/User";
import { useAuthQuery } from "hooks/useAuthQuery";
import { useMemo } from "react";

interface UseGetCompanyAgreementsProps
    extends GetCompanyAgreementsHttpRequest {}

export const useGetCompanyAgreements = (
    params?: UseGetCompanyAgreementsProps
) => {
    const {
        page,
        limit,
        sortBy,
        sortDir,
        id,
        statuses,
        from,
        itemIds,
        itemTypes,
        networks,
        entities,
    } = params || {};

    const { getEntityId, getSessionToken } = useUser();

    // Scoped query key
    const queryKey = [
        QueryKey.CompanyAgreements,
        {
            page,
            limit,
            sortBy,
            sortDir,
            id,
            statuses,
            from,
            itemIds,
            itemTypes,
            networks,
            entities,
        },
    ];

    const {
        data: getCompanyAgreementsData,
        isError: getCompanyAgreementsIsError,
        isLoading: getCompanyAgreementsIsLoading,
        dataUpdatedAt: getCompanyAgreementsDataUpdatedAt,
        refetch: getCompanyAgreementsRefetch,
        isFetching: getCompanyAgreementsIsFetching,
    } = useAuthQuery({
        queryKey: queryKey,
        queryFn: () =>
            getCompanyAgreements(
                getEntityId(),
                {
                    page,
                    limit,
                    sortBy,
                    sortDir,
                    id,
                    statuses,
                    from,
                    itemIds,
                    itemTypes,
                    networks,
                    entities,
                },
                { Authorization: getSessionToken() }
            ),
    });

    const { totalResults, agreements } = useMemo(
        () => getCompanyAgreementsData || { totalResults: 0, agreements: [] },
        [getCompanyAgreementsData]
    );

    return {
        totalResults,
        agreements,
        getCompanyAgreementsIsError,
        getCompanyAgreementsIsLoading,
        getCompanyAgreementsDataUpdatedAt,
        getCompanyAgreementsRefetch,
        getCompanyAgreementsIsFetching,
    };
};
