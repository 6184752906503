import * as S from "./style";
import { useState, useEffect, useCallback, ReactNode, useMemo } from "react";
import { formatUnits } from "ethers";
import { Spacing } from "theme/spacing";
import { TokenWithoutRequiredAggregator } from "types/common";
import { regex, regexToPattern } from "utils/regex";
import { toNumber } from "utils/numbers";
import { toDollar } from "utils/financial";
import { tokenToCents } from "utils/exchangeRates";
import { useNotificationQueue } from "context/NotificationQueue";
import { useSafeApi } from "hooks/useSafeApi";
import InputEditInline, { State } from "components/InputEditInline";
import { NotificationType } from "components/Notification";
import { useWallet } from "context/Wallet";

interface TokenAllowanceProps {
    token: TokenWithoutRequiredAggregator;
    contract: string;
    company?: string;
    wallet?: HexAddress;
    symbolPosition?: `before` | `after`;
    spaceBetween?: Spacing;
    usdRate?: number;
    icons?: boolean;
}

const TokenAllowance = ({
    token,
    contract,
    company,
    wallet,
    symbolPosition = `before`,
    spaceBetween,
    usdRate,
    icons = false,
}: TokenAllowanceProps) => {
    const {
        walletConnected,
        networkConnected,
        getTokenAllowance,
        setTokenAllowance,
        safeWallet,
    } = useWallet();
    const { addNotification, removeNotification } = useNotificationQueue();
    const { sendApproveAllownaceForSafe } = useSafeApi(
        walletConnected,
        safeWallet
    );
    const [allowance, setAllowance] = useState<string | null>(null);
    const [state, setState] = useState<State>();

    const walletAddress =
        wallet || walletConnected?.proxyFor || walletConnected?.address;

    const getAllowance = useCallback(async () => {
        if (!networkConnected?.networkId || !walletAddress) {
            setAllowance(null);
            return;
        }

        try {
            const tokenAllowance = await getTokenAllowance({
                tokenAddress: token.address,
                networkId: networkConnected.networkId,
                walletAddress,
                contractAddress: contract,
                force: true,
            });

            setAllowance(formatUnits(tokenAllowance, token.decimals));
        } catch (error) {
            setAllowance(null);
        }
    }, [
        getTokenAllowance,
        token?.address,
        token?.decimals,
        walletAddress,
        networkConnected?.networkId,
        contract,
    ]);

    const onSubmit = useCallback(
        async (proposedAllowance: string) => {
            // Sanitize input
            proposedAllowance = String(toNumber(proposedAllowance));

            const notificationId: string = addNotification({
                msg: `Updating ${
                    token.name
                } authorization to ${proposedAllowance}${
                    company ? ` for ${company}'s contract...` : `...`
                }`,
                type: NotificationType.WORKING,
                expires: false,
            });

            setAllowance(proposedAllowance);

            (walletConnected?.proxyFor
                ? sendApproveAllownaceForSafe({
                      token: token,
                      contract,
                      amount: proposedAllowance,
                  })
                : setTokenAllowance({
                      contractAddress: contract,
                      tokenAddress: token.address,
                      amount: proposedAllowance,
                      decimals: token.decimals,
                      awaitConfirm: true,
                  })
            )
                .then((result) => {
                    if (result === null)
                        throw new Error(`Your authorization was not increased`);

                    setAllowance(null);
                    getAllowance();
                    addNotification({
                        msg: walletConnected?.proxyFor
                            ? (result as ReactNode)
                            : `${token.name} authorization updated${
                                  company ? ` for ${company}'s contract...` : ``
                              }`,
                        type: NotificationType.SUCCESS,
                    });
                })
                .catch((error: string) => {
                    addNotification({
                        msg: error,
                        type: NotificationType.ERROR,
                    });
                    setAllowance(allowance);
                })
                .finally(() => {
                    removeNotification(notificationId);
                    setState(State.Idle);
                });
        },
        [
            getAllowance,
            setTokenAllowance,
            sendApproveAllownaceForSafe,
            addNotification,
            removeNotification,
            allowance,
            contract,
            token,
            company,
            walletConnected?.proxyFor,
        ]
    );

    const onStateChange = useCallback((newState: State) => {
        setState(newState);
    }, []);

    useEffect(() => {
        if (allowance === null) getAllowance();
    }, [allowance, getAllowance]);

    const Symbol = useMemo(
        () => (
            <S.TokenSymbol title={token.name} htmlFor={token.address}>
                {token.symbol}
            </S.TokenSymbol>
        ),
        [token]
    );

    const UsdRate = useMemo(() => {
        if (!usdRate || allowance === null) return null;

        const usdValue = tokenToCents(allowance, usdRate);
        return <>({toDollar(usdValue)})</>;
    }, [usdRate, allowance]);

    const before = symbolPosition === `before` && Symbol;
    const after = (
        <>
            {symbolPosition === `after` && Symbol}
            {UsdRate &&
                state === State.Idle &&
                (symbolPosition === `after` ? <>&nbsp;{UsdRate}</> : UsdRate)}
        </>
    );

    return (
        <S.TokenAllowance disabled={state === State.Working}>
            {!allowance ? (
                <S.LoadingAllowance desaturate />
            ) : (
                <InputEditInline
                    id={token.address}
                    value={allowance || `-`}
                    save={`update`}
                    icons={icons}
                    before={before}
                    after={after}
                    pattern={regexToPattern(regex.coins)}
                    state={state}
                    disabled={!allowance}
                    onSubmit={onSubmit}
                    onStateChange={onStateChange}
                    spaceBetween={spaceBetween}
                    slim
                />
            )}
        </S.TokenAllowance>
    );
};
export default TokenAllowance;
