export enum QueryKey {
    CompanyTransfers = `getCompanyTransfers`,
    CompanyAgreements = `getCompanyAgreements`,
    CompanyConfig = `getCompanyConfig`,
    CompanyItems = `getCompanyItems`,
    Networks = `getNetworks`,
    Tokens = `getTokensMetadata`,
}

export interface PagingQueryParams<TSortableColId = string> {
    page?: number;
    limit?: number;
    sortBy?: TSortableColId;
    sortDir?: "asc" | "desc";
}
