import * as S from "./style";
import Button, { ButtonVariants } from "components/Button";
import { useState, useEffect } from "react";
import Refresh from "components/icons/Refresh";
import Spinner from "components/Spinner";
import colors from "theme/colors";

export enum CounterType {
    Stopped, // Not a running count, but will display `value`
    Running, // Running, but show "< 1 min" for seconds
    Seconds, // Running, will show seconds under 1 min
}

interface RefreshCounterProps {
    refreshedAt?: number; // timestamp of the last refresh
    counter?: CounterType; // counter style
    onRefresh?: () => void; // refresh handler
    refreshing?: boolean;
}

const RefreshCounter = ({
    refreshedAt = Date.now(),
    counter = CounterType.Running,
    onRefresh,
    refreshing = false,
    ...props
}: RefreshCounterProps) => {
    // [ ] If 0 is received for refreshAt (due to error), then this displays hours since epoch

    // If 0, return relative time as past tense using -0
    const secondsSinceRefresh =
        Math.floor((refreshedAt - Date.now()) / 1000) || -0;
    const [secondsElapsed, setSecondsElapsed] = useState(secondsSinceRefresh);

    useEffect(() => {
        setSecondsElapsed(Math.floor((refreshedAt - Date.now()) / 1000) || -0);
    }, [refreshedAt, counter]);

    useEffect(() => {
        if (counter === CounterType.Stopped) return;

        const interval = setInterval(() => {
            setSecondsElapsed(secondsElapsed - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [secondsElapsed, counter]);

    const handleRefreshClick = () => {
        if (!onRefresh) return;
        onRefresh();
    };

    // Relative time in local format
    const deltaTime = new Intl.RelativeTimeFormat(`en`, {
        numeric: `always`,
        style: `long`,
    });

    // Determine if expressed in seconds, minutes or hours
    const relativeTime =
        Math.abs(secondsElapsed) < 60 ? (
            counter === CounterType.Seconds ? (
                deltaTime.format(secondsElapsed, `second`)
            ) : (
                <>
                    &lt;{" "}
                    {deltaTime.format(
                        Math.ceil(secondsElapsed / 60) - 1,
                        `minute`
                    )}
                </>
            )
        ) : Math.abs(secondsElapsed) < 3600 ? (
            deltaTime.format(Math.ceil(secondsElapsed / 60), `minute`)
        ) : (
            deltaTime.format(Math.ceil(secondsElapsed / 3600), `hour`)
        );

    if (refreshing) {
        return (
            <S.RefreshingMessage>
                <S.RefreshingSpinner /> Refreshing
            </S.RefreshingMessage>
        );
    }

    return (
        <S.RefreshCounter
            dateTime={new Date(refreshedAt).toISOString()}
            {...props}
        >
            {onRefresh ? (
                <>
                    <Button
                        onClick={handleRefreshClick}
                        variant={ButtonVariants.Anchor}
                    >
                        <Refresh fill={colors.neutralLight} width="1em" />
                    </Button>
                    <small>refreshed {relativeTime}</small>
                </>
            ) : (
                <small>Refreshed {relativeTime}</small>
            )}
        </S.RefreshCounter>
    );
};

export default RefreshCounter;
