import { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { apiServerUrl } from "default-variables";
import { useNotificationQueue } from "context/NotificationQueue";
import Loading from "components/Loading";
import { regex } from "utils/regex";
import { NotificationType } from "components/Notification";
import { UserRole, tierStrToEnum, useUser } from "context/User";
import { LogUserInResponse, LoginContextData } from "components/LoginWeb2";
import { getErrorMessage } from "utils/errors";
import Button, { ButtonSizes } from "components/Button";
import Form from "components/Form";
import Title from "components/Title";
import { Spacing } from "theme/spacing";
import Field from "components/Field";
import Input, { InputSizes } from "components/Input";
import Hint, { HintType } from "components/Hint";

const LoginForm = () => {
    const { addNotification } = useNotificationQueue();
    const { newUserSession } = useUser();
    const navigate = useNavigate();

    const { redirectPath } = useOutletContext<LoginContextData>();

    const [state, setState] = useState({
        email: { value: ``, error: false },
        password: { value: ``, error: false },
        loading: false,
    });
    const { email, password, loading } = state;

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            email: { value: event.target.value, error: false },
        });
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setState({
            ...state,
            password: { value: event.target.value, error: false },
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        // This prevents the form from submitting without valid credentials
        // Replaced this when MUI is in or out
        const valid = { email: true, password: true };
        if (!email.value.match(regex.email)) {
            valid.email = false;
        }
        if (!password.value.match(regex.password)) {
            valid.password = false;
        }
        setState({
            email: { ...email, error: !valid.email },
            password: { ...password, error: !valid.password },
            loading: valid.email && valid.password, // If both are valid, it's time to start loading data
        });
        if (!valid.email || !valid.password) return;

        let res, data;
        try {
            res = await fetch(`${apiServerUrl}/v1/user/login`, {
                method: `POST`,
                body: JSON.stringify({
                    email: email.value.trim(),
                    password: password.value,
                }),
            });
            data = await res.json();

            if (!res.ok) {
                throw await data;
            }
        } catch (error: unknown) {
            const errorMsg = getErrorMessage(error);
            addNotification({ msg: errorMsg, type: NotificationType.ERROR });
            return;
        } finally {
            setState({
                loading: false,
                email: { ...email, error: false },
                password: { ...password, error: false },
            });
        }

        const {
            token,
            entityId,
            companyName,
            roles,
            delegatedSigning,
            email: serverEmail,
        }: LogUserInResponse = data;
        if (token && entityId) {
            newUserSession({
                token,
                roles: roles as UserRole[],
                entityId,
                tier: tierStrToEnum(`guest`),
                companyName,
                email: serverEmail,
                delegatedSigning,
            });

            navigate(redirectPath || `/`);
        } else {
            addNotification({
                msg: `Something went wrong`,
                type: NotificationType.ERROR,
            });
        }
    };

    return (
        <>
            {state.loading && <Loading />}
            <Form onSubmit={handleSubmit}>
                <Title level="h2" spacing={[Spacing.sm]}>
                    Welcome
                </Title>
                <Field>
                    <Input
                        placeholder="Email"
                        inputSize={InputSizes.Large}
                        value={email.value}
                        onChange={handleEmailChange}
                        aria-invalid={email.error}
                    />
                    {email.error && (
                        <Hint type={HintType.Error}>
                            Must be a valid email address
                        </Hint>
                    )}
                </Field>

                <Field>
                    <Input
                        placeholder="Password"
                        type="password"
                        inputSize={InputSizes.Large}
                        value={password.value}
                        onChange={handlePasswordChange}
                        aria-invalid={password.error}
                    />
                    {password.error && (
                        <Hint type={HintType.Error}>
                            Passwords are at least 8 characters in length.
                        </Hint>
                    )}
                </Field>
                <Field>
                    <Button
                        type="submit"
                        loading={loading}
                        full
                        size={ButtonSizes.Large}
                    >
                        Login
                    </Button>
                </Field>

                <Link
                    to="/login/reset-password"
                    style={{ textDecoration: "none" }}
                >
                    Forgot your password?
                </Link>
            </Form>
        </>
    );
};

export default LoginForm;
