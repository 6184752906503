import * as S from "./style";
import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useRef,
    useEffect,
} from "react";
import { Spacing } from "theme/spacing";
import { regex } from "utils/regex";
import { validateEmailAddress } from "utils/formValidation";
import { SelfServeNewUser } from "api";
import Label from "components/Label";
import Input from "components/Input";
import * as Sentry from "@sentry/react";

export interface UserEmailFieldProps {
    disabled?: boolean;
    label?: React.ReactNode;
    defaultEmail?: string;
    onChange?: (email: string) => void;
}

export type UserEmailFieldRef = {
    email: string;
    validate: () => boolean;
    isValid: boolean;
};

function UserEmailField(
    {
        disabled,
        label = "Email",
        defaultEmail = "",
        onChange,
    }: UserEmailFieldProps,
    ref: Ref<UserEmailFieldRef>
) {
    const [email, setEmail] = useState<SelfServeNewUser["email"]>(defaultEmail);
    const emailRef = useRef<HTMLInputElement>(null);
    const validationMessage = "Please enter a valid email";
    const isValid: boolean = regex.email.test(email);

    const validate = () => {
        if (!emailRef.current) {
            return false;
        }

        return validateEmailAddress({
            input: emailRef.current,
            errorMessage: validationMessage,
        });
    };
    useImperativeHandle(ref, () => ({ email, validate, isValid }));

    useEffect(() => {
        if (onChange) {
            Sentry.setTag("Email", email);
            onChange(email);
        }
    }, [onChange, email]);

    return (
        <S.UserEmailField disabled={disabled} spacing={[Spacing.md]}>
            <Label htmlFor="email">{label}</Label>
            <Input
                innerRef={emailRef}
                type="email"
                name="email"
                placeholder="your@email.com"
                value={email}
                disabled={disabled}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={(event) =>
                    validateEmailAddress({
                        input: event.target,
                        errorMessage: validationMessage,
                        reportValidity: false,
                    })
                }
            />
        </S.UserEmailField>
    );
}
export default forwardRef(UserEmailField);
