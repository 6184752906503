import * as S from "./style";

interface SpinnerProps {
    desaturate?: boolean;
    color?: string;
}

const Spinner = ({ desaturate = false, color, ...props }: SpinnerProps) => {
    return (
        <S.CropSpinner {...props}>
            <S.Spinner desaturate={desaturate} color={color} />
        </S.CropSpinner>
    );
};

export default Spinner;
