import { ReactNode } from "react";
import {
    GetCustomerDataResponse,
    CustomerTransactionDetail,
} from "api/types/customer";
import { ExchangableToken } from "types/common";

export type CustomerData = Omit<
    GetCustomerDataResponse,
    `transactions` | `tokens`
> & {
    transactions: CustomerTransactionDetail[];
    tokens: ExchangableToken[];
};

export enum CustomerAuthorizationType {
    Subscription = `Subscription`,
    Invoice = `Invoice`,
    Other = `Other`,
}

export interface CustomerAuthorization {
    id: string;
    type: CustomerAuthorizationType;
    contract: string;
    entityId: string;
    entityName: string;
    itemName: string;
    amount: bigint | null; // `null` if an item is priced in token AND there is no next transaction priced in USD
    frequency: string;
    priceVaries: string;
    nextPayment: string;
    active: boolean;
    canceledOn: string;
    statusBadge: ReactNode | null;
    wallet: HexAddress;
    email: string | null;
    token: ExchangableToken | undefined;
    tokenSymbol: string;
    tokenAddress: string;
    networkHex: HexAddress;
    networkName: string;
    transactions: {
        id: string;
        billDate: number;
        datePaid: number | null;
        amount: string;
        status: string;
        txHash: ReactNode;
        itemName: string;
        invoiceId: string;
    }[];
}

export type CustomerInvoicePayment = Pick<
    CustomerAuthorization["transactions"][number],
    `id` | `itemName` | `amount` | `txHash` | `billDate`
>;
