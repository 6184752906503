import * as S from "./style";
import { HTMLAttributes } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Spacing } from "theme/spacing";
import { toDollar } from "utils/financial";
import { getReadableDate } from "utils/dates";
import {
    CustomerAuthorizationType,
    CustomerInvoicePayment,
} from "customer/types";
import useGetCustomerData from "customer/hooks/useGetCustomerData";
import useAuthorizations from "customer/hooks/useAuthorizations";
import Anchor from "components/Anchor";
import Paragraph from "components/Paragraph";
import SectionAndTitle from "customer/components/SectionAndTitle";
import Title from "components/Title";
import TooltipOnOverflow from "components/TooltipOnOverflow";

interface MerchantProps extends HTMLAttributes<HTMLDivElement> {}

const Merchant = ({ ...props }: MerchantProps) => {
    const { entityId } = useParams();
    const { getAuthorizations } = useAuthorizations();

    const { data } = useGetCustomerData();

    const merchant = data?.entities?.find(
        ({ entityId: id }) => id === entityId
    );

    if (!merchant) {
        // [ ] Register error to dashboard
        // addNotification({
        //     msg: `Authorization not found`,
        //     type: NotificationType.ERROR,
        // });
        return <Navigate to="/" />;
    }

    const subscriptions = getAuthorizations({
        entityId,
        type: CustomerAuthorizationType.Subscription,
    });

    const invoices = getAuthorizations({
        entityId,
    })
        .reduce<CustomerInvoicePayment[]>((paidTxs, { transactions }) => {
            if (!transactions) return paidTxs;

            const merchantTxs = transactions.map<CustomerInvoicePayment>(
                ({ id, billDate, amount, txHash, itemName }) => ({
                    id,
                    billDate,
                    itemName,
                    amount,
                    txHash,
                })
            );

            return [...paidTxs, ...merchantTxs];
        }, [])
        .sort((a, b) => b.billDate - a.billDate);

    return (
        <article {...props}>
            {merchant?.name && (
                <Title bold={false} spacing={[Spacing.sm, Spacing.xxl]}>
                    {merchant.name}
                </Title>
            )}
            {subscriptions.length > 0 && (
                <SectionAndTitle title="Subscriptions">
                    <S.SubTable>
                        {subscriptions.map(
                            ({
                                id,
                                itemName,
                                amount,
                                priceVaries,
                                statusBadge,
                            }) => (
                                <S.ListRow key={id}>
                                    <TooltipOnOverflow
                                        as="data"
                                        tooltipProps={{ title: itemName }}
                                    >
                                        <Anchor href={`/${entityId}/${id}`}>
                                            {itemName}
                                        </Anchor>
                                    </TooltipOnOverflow>
                                    <data>
                                        {amount === null
                                            ? `Priced in token`
                                            : priceVaries && !amount
                                            ? priceVaries
                                            : toDollar(amount)}
                                    </data>
                                    <data>{statusBadge}</data>
                                </S.ListRow>
                            )
                        )}
                    </S.SubTable>
                </SectionAndTitle>
            )}
            <SectionAndTitle title="Invoices">
                {invoices.length ? (
                    <S.InvoiceTable>
                        {invoices.map(
                            ({ id, billDate, amount, txHash, itemName }) => (
                                <S.ListRow key={id}>
                                    <data>{getReadableDate(billDate)}</data>
                                    <data>{amount}</data>
                                    <data>{txHash}</data>
                                    <TooltipOnOverflow as="data">
                                        {itemName}
                                    </TooltipOnOverflow>
                                </S.ListRow>
                            )
                        )}
                    </S.InvoiceTable>
                ) : (
                    <Paragraph>No paid invoices</Paragraph>
                )}
            </SectionAndTitle>
        </article>
    );
};

export default Merchant;
