import * as S from "./style";
import Button, { ButtonVariants } from "components/Button";
import { useFetch } from "hooks/useFetch";
import { apiServerUrl } from "default-variables";
import { useEffect } from "react";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";
import { useUser } from "context/User";
import DescriptionList, {
    DescriptionListItem,
} from "components/DescriptionList";
import SubSection from "components/SubSection";
import { Spacing } from "theme/spacing";
import { formatDateTimeFromSeconds } from "utils/datetime";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
} from "api";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import { useEns } from "context/EnsProvider";
import Anchor from "components/Anchor";
import Icon from "components/Icon";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import { BlockExplorerEntityType } from "utils/urls";
import { CompanyItemWithNumberAmount } from "company/hooks/useGetCompanyItems";
import useInvalidateQuery from "hooks/useInvalidateQuery";
import { QueryKey } from "api/types";

interface CancelAccountFormProps {
    onClose: () => void;
    onComplete: () => void;
    agreementId: string;
    createdAt: number;
    senderWallet: string;
    senderEmail: string | null;
    items: CompanyItemWithNumberAmount[];
    token?: GeneralTokenDetailsResponse;
    network?: CommonBlockchainNetworkResponse;
}

const CancelAccountForm = ({
    onClose: handleClose,
    onComplete: handleComplete,
    agreementId,
    createdAt,
    senderWallet,
    senderEmail,
    items,
    token,
    network,
}: CancelAccountFormProps) => {
    const { invalidateQueries } = useInvalidateQuery();
    const { getEntityId, getSessionToken } = useUser();
    const { addNotification, removeNotification } = useNotificationQueue();
    const { getEnsRecord } = useEns();

    const {
        data: cancelResult,
        error,
        loading,
        refetch: patchCancelAgreement,
    } = useFetch(
        `${apiServerUrl}/api/v1/company/agreements`,
        {
            method: `PATCH`,
            headers: {
                Authorization: getSessionToken(),
                "Content-Type": "application/json",
                "entity-id": getEntityId(),
            },
            body: JSON.stringify({
                agreementIds: [agreementId],
            }),
        },
        false
    );

    const handleProceedWithCancel = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        addNotification({
            msg: `Cancelling agreement ${agreementId}`,
            type: NotificationType.WORKING,
            expires: false,
            customId: agreementId,
        });

        await patchCancelAgreement(true);
    };

    useEffect(() => {
        if (!cancelResult && !error) return;

        removeNotification(agreementId);

        if (error) {
            addNotification({
                msg: `There was a problem canceling an agreement`,
                type: NotificationType.ERROR,
            });
        } else {
            addNotification({
                msg: `${
                    cancelResult?.agreements.length === 1
                        ? `The agreement was`
                        : `${cancelResult?.agreements.length} agreements were`
                } successfully canceled`,
                type: NotificationType.SUCCESS,
            });
            invalidateQueries(QueryKey.CompanyAgreements);
        }

        handleComplete();
    }, [
        addNotification,
        agreementId,
        cancelResult,
        error,
        invalidateQueries,
        handleComplete,
        removeNotification,
    ]);

    const agreementDetails: DescriptionListItem[] = [
        {
            term: "Item",
            definition: items.map((item) => item.name).join(", "),
            style: S.DetailItem,
        },
        {
            term: "Created",
            definition: formatDateTimeFromSeconds(createdAt),
            style: S.DetailItem,
        },
        {
            term: "Wallet",
            definition: (
                <DynamicWalletAddressDisplay
                    address={senderWallet}
                    ensName={getEnsRecord(senderWallet)?.name}
                    networkId={network?.hexId}
                />
            ),
            style: S.DetailItem,
        },
    ];
    if (senderEmail) {
        agreementDetails.push({
            term: "Email",
            definition: senderEmail ? (
                <Anchor href={`mailto:${senderEmail}`} inheritColor={true}>
                    {senderEmail}
                </Anchor>
            ) : undefined,
            style: S.DetailItem,
        });
    }
    if (token) {
        agreementDetails.push({
            term: "Token",
            definition: (
                <>
                    <Icon
                        src={token.logoUrl}
                        alt={`${token.symbol} logo`}
                        size="1.25rem"
                        wrapped
                        round
                        tighten
                    />
                    <span>{token.symbol}</span>
                    <span>
                        (
                        {
                            <DynamicAddressDisplay
                                address={token.address}
                                networkId={network?.hexId || ``}
                                type={BlockExplorerEntityType.Token}
                            ></DynamicAddressDisplay>
                        }
                        )
                    </span>
                </>
            ),
            style: S.DetailItem,
        });
    }

    return (
        <S.CancelAccountForm onSubmit={handleProceedWithCancel}>
            <SubSection
                title="Are you sure you want to cancel this agreement?"
                spacing={[Spacing.xs, Spacing.lg]}
            >
                <p>
                    Cancelling this agreement will delete all <em>future</em>{" "}
                    invoices, but will not cancel any due or overdue transfer
                    requests.
                </p>
            </SubSection>
            <SubSection title="Details" spacing={[Spacing.lg]}>
                <DescriptionList items={agreementDetails} separator=":" />
            </SubSection>
            <SubSection spacing={[Spacing.xl]}>
                <S.Buttons>
                    {loading ? (
                        <>
                            <Button disabled>Canceling agreement...</Button>
                            <Button
                                variant={ButtonVariants.NeutralOutlined}
                                type="button"
                                disabled
                            >
                                Exit without canceling
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button type="submit">
                                Proceed with cancelation
                            </Button>
                            <Button
                                variant={ButtonVariants.NeutralOutlined}
                                type="button"
                                onClick={handleClose}
                            >
                                Exit without canceling
                            </Button>
                        </>
                    )}
                </S.Buttons>
            </SubSection>
        </S.CancelAccountForm>
    );
};

export default CancelAccountForm;
