import { forwardRef, Ref } from "react";
import SelectMultiple from "components/SelectMultiple";
import * as S from "./style";
import Hint, { HintType } from "components/Hint";
import Anchor from "components/Anchor";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import { GeneralTokenDetailsResponse } from "api";
import { useEns } from "context/EnsProvider";
import { ItemNetwork, ItemNetworkDisabledReason } from "company/utils/items";
import { getNetworkName } from "company/utils/networks";

export interface ItemAcceptedTokensForNetworkFieldProps {
    disabled: boolean;
    itemNetwork: ItemNetwork;
    selectedTokens: GeneralTokenDetailsResponse[];
    onChange: (tokens: GeneralTokenDetailsResponse[]) => void;
}

export type ItemAcceptedTokensForNetworkFieldRef = {
    acceptedTokensForNetwork: string[];
};

function ItemAcceptedTokensForNetworkField(
    {
        disabled,
        itemNetwork,
        selectedTokens,
        onChange,
    }: ItemAcceptedTokensForNetworkFieldProps,
    ref: Ref<ItemAcceptedTokensForNetworkFieldRef>
) {
    const { getEnsRecord } = useEns();

    const {
        network,
        acceptedTokens,
        inboundTreasuryAddress,
        showInboundTreasuryAddress,
    } = itemNetwork;

    const networkNameForDisplay = getNetworkName(network);

    if (itemNetwork.disabled) {
        const missingContract =
            itemNetwork.disabledReason ===
            ItemNetworkDisabledReason.MissingContract;
        const selfServeDisabled =
            itemNetwork.disabledReason ===
            ItemNetworkDisabledReason.SelfServeUpgradeRequired;
        return (
            <S.AcceptedTokensField key={network.id}>
                <S.AcceptedTokensLabel>
                    {networkNameForDisplay}
                </S.AcceptedTokensLabel>
                {missingContract && (
                    <Hint type={HintType.Warning}>
                        Contract not configured,{" "}
                        <Anchor
                            href={`mailto:${
                                import.meta.env.VITE_EMAIL_SUPPORT
                            }`}
                        >
                            contact us
                        </Anchor>{" "}
                        to setup a contract.
                    </Hint>
                )}

                {selfServeDisabled && (
                    <Hint type={HintType.Warning}>Requires upgrade</Hint>
                )}
            </S.AcceptedTokensField>
        );
    }

    // No treasury address setup
    if (!inboundTreasuryAddress && showInboundTreasuryAddress) {
        return (
            <S.AcceptedTokensField key={network.id}>
                <S.AcceptedTokensLabel>
                    {networkNameForDisplay}
                </S.AcceptedTokensLabel>
                <Hint type={HintType.Warning}>
                    No receiving wallet configured
                </Hint>
            </S.AcceptedTokensField>
        );
    }

    const tokenOptions = acceptedTokens.map(
        (token: GeneralTokenDetailsResponse) => {
            const label = token.symbol;
            return {
                value: token,
                label: label,
                imgSrc: token.logoUrl,
                imgAlt: `${token.name} logo`,
            };
        }
    );

    return (
        <S.AcceptedTokensField disabled={disabled}>
            <S.AcceptedTokensLabel>
                {networkNameForDisplay}
            </S.AcceptedTokensLabel>
            <S.AcceptedTokensInputWrapper>
                <SelectMultiple
                    options={tokenOptions}
                    name="tokenAddresses"
                    closeMenuOnSelect={false}
                    values={selectedTokens}
                    disabled={disabled}
                    onChange={(values: GeneralTokenDetailsResponse[]) =>
                        onChange(values)
                    }
                />
                {inboundTreasuryAddress && showInboundTreasuryAddress && (
                    <Hint>
                        Receiving wallet:{" "}
                        <DynamicWalletAddressDisplay
                            address={inboundTreasuryAddress}
                            ensName={getEnsRecord(inboundTreasuryAddress)?.name}
                            networkId={network.hexId}
                            anchor={true}
                        />
                    </Hint>
                )}
            </S.AcceptedTokensInputWrapper>
        </S.AcceptedTokensField>
    );
}
export default forwardRef(ItemAcceptedTokensForNetworkField);
