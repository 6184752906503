import * as S from "./style";
import {
    HTMLAttributes,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useCheckoutData } from "checkout/context/CheckoutData";
import Input from "components/Input";
import Button from "components/Button";
import { getVerifyCoupons } from "api";
import Hint, { HintType } from "components/Hint";
import Badge from "components/Badge";
import Anchor from "components/Anchor";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";
import { Density } from "types/common-enums";

interface CouponFormProps extends HTMLAttributes<HTMLElement> {}

const CouponForm = (props: CouponFormProps) => {
    const { entity, items, setCoupons, couponsWithDetails, queryParams } =
        useCheckoutData();

    const couponCode = useRef<string>(queryParams.coupon || "");
    const [invalidCoupon, setInvalidCoupon] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const { addNotification } = useNotificationQueue();

    const onSubmit = useCallback(async () => {
        if (fetching) return;
        setFetching(true);

        const { data, response } = await getVerifyCoupons({
            entityId: entity?.entityId || "",
            couponCodes: [couponCode.current],
            itemIds: items.map((item) => item.id),
        });

        if (!response.ok) {
            return addNotification({
                msg: "Something went wrong while fetching coupon",
                type: NotificationType.ERROR,
            });
        }

        if (data && data.coupons) {
            const couponsResponse = data.coupons;
            if (couponsResponse.length > 0) {
                setCoupons(couponsResponse);
            } else {
                setInvalidCoupon(true);
            }
        }

        setFetching(false);
    }, [addNotification, entity?.entityId, fetching, items, setCoupons]);

    const updateCouponCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        couponCode.current = event.target.value;
        setInvalidCoupon(false);
    };

    const resetCoupon = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setCoupons([]);
    };

    useEffect(() => {
        if (queryParams.coupon) {
            couponCode.current = queryParams.coupon;
            setCoupons([]);
            onSubmit();
        }
    }, [onSubmit, queryParams.coupon, setCoupons]);

    if (couponsWithDetails.length > 0) {
        const couponDetails = couponsWithDetails[0];
        return (
            <p>
                <b>
                    {couponDetails.name} -{" "}
                    {couponDetails.couponDiscountForDisplay} Off
                </b>
                <br />
                <Badge density={Density.Compact}>
                    {couponDetails.code}
                </Badge>{" "}
                <Anchor
                    underlined={false}
                    onClick={(event) => resetCoupon(event)}
                >
                    Edit
                </Anchor>
            </p>
        );
    }

    return (
        <S.CouponForm onSubmit={onSubmit}>
            <S.InputButtonWrapper>
                <Input
                    value={couponCode.current}
                    placeholder="Add coupon code"
                    onChange={updateCouponCode}
                    className={"fs-unmask"}
                />
                <Button loading={fetching}>Apply</Button>
            </S.InputButtonWrapper>
            {invalidCoupon && (
                <Hint type={HintType.Error}>Not a valid coupon</Hint>
            )}
        </S.CouponForm>
    );
};

export default CouponForm;
