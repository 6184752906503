import * as S from "./style";
import {
    ReactNode,
    HTMLAttributes,
    useEffect,
    useRef,
    useState,
    ComponentProps,
} from "react";
import Tooltip from "components/Tooltip";

interface TooltipOnOverflowProps<T extends keyof JSX.IntrinsicElements>
    extends HTMLAttributes<JSX.IntrinsicElements[T]> {
    children: ReactNode;
    as?: T;
    tooltipProps?: Omit<ComponentProps<typeof Tooltip>, "children">;
}

// Singleton ResizeObserver (may consider sharing across components)
const resizeObserverCallbacks = new Map<HTMLElement, () => void>();
const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
        const callback = resizeObserverCallbacks.get(
            entry.target as HTMLElement
        );
        if (callback) {
            callback();
        }
    });
});

const TooltipOnOverflow = <T extends keyof JSX.IntrinsicElements = "span">({
    children,
    as,
    tooltipProps,
    ...props
}: TooltipOnOverflowProps<T>) => {
    // Use a more generic ref type to accommodate different HTML elements
    const textRef = useRef<HTMLElement | null>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                setIsOverflowing(
                    textRef.current.scrollWidth > textRef.current.clientWidth
                );
            }
        };

        // Check on mount
        checkOverflow();

        // Register the callback and observe the element
        const currentElement = textRef.current;
        if (currentElement) {
            resizeObserverCallbacks.set(currentElement, checkOverflow);
            resizeObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                resizeObserver.unobserve(currentElement);
                resizeObserverCallbacks.delete(currentElement);
            }
        };
    }, [children]);

    return (
        <S.TooltipOnOverflow as={as as any} ref={textRef} {...props}>
            {isOverflowing ? (
                <Tooltip title={children} {...tooltipProps}>
                    {children}
                </Tooltip>
            ) : (
                children
            )}
        </S.TooltipOnOverflow>
    );
};

export default TooltipOnOverflow;
