import { HTMLAttributes } from "react";
import * as S from "./styles";
import { SpacingTrack } from "theme/spacing";

export type TitleLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
    level?: TitleLevel;
    size?: TitleLevel;
    bold?: boolean;
    spacing?: SpacingTrack;
    wrap?: boolean;
    lineUnder?: boolean;
    uppercase?: boolean;
}

const Title = ({
    children,
    level = "h1",
    bold = true,
    className,
    size = level,
    spacing,
    wrap = true,
    lineUnder = false,
    uppercase = false,
    ...props
}: TitleProps) => {
    return (
        <S.Title
            as={level}
            role="heading"
            size={size}
            bold={bold}
            spacing={spacing}
            wrap={wrap}
            lineUnder={lineUnder}
            uppercase={uppercase}
            className={className}
            {...props}
        >
            {children}
        </S.Title>
    );
};

export default Title;
