import * as S from "./style";
import Section from "components/Section";
import { useUser, UserRole } from "context/User";
import AddItemButton from "company/components/items/AddItemButton";
import { ItemFrequencyNames } from "utils/items";
import ItemsTable from "company/components/items/ItemsTable";
import { ItemValues } from "company/hooks/useFormatCompanyItemsTable";

const headings = [
    ItemValues.entity,
    ItemValues.name,
    ItemValues.amount,
    ItemValues.tokens,
    ItemValues.invoicing,
    ItemValues.manage,
];

const OneTimePayments = () => {
    const { hasRole } = useUser();
    const canManage = hasRole(UserRole.COMPANY);

    return (
        <>
            {canManage && (
                <Section>
                    <S.Filters>
                        <AddItemButton
                            itemFrequencyName={
                                ItemFrequencyNames.OneTimePayment
                            }
                        />
                    </S.Filters>
                </Section>
            )}
            <Section>
                <ItemsTable
                    headings={headings}
                    itemFrequency={ItemFrequencyNames.OneTimePayment}
                    active={true}
                />
            </Section>
        </>
    );
};

export default OneTimePayments;
