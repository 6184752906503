import * as S from "./style";
import { useEffect } from "react";
import Button, { ButtonVariants } from "components/Button";
import { formatUnits } from "ethers";
import { toDollar, toCoin } from "utils/financial";
import { getDateTimeFromSeconds } from "utils/datetime";
import { firstToUpper } from "utils/strings";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { useUser } from "context/User";
import { CancelTransfersRequest, cancelCompanyTransfer } from "api";
import useCustomMutation from "hooks/useCustomMutation";
import { QueryKey } from "api/types";
import useInvalidateQuery from "hooks/useInvalidateQuery";

interface CancelTransactionFormProps {
    onClose: () => void;
    transferId: string;
}

const CancelTransactionForm = ({
    transferId,
    onClose,
}: CancelTransactionFormProps) => {
    const { tokens } = useGetTokensMetadata();
    const { getEntityId, getSessionToken } = useUser();

    const { networks } = useGetNetworks();
    const { items } = useGetCompanyItems();

    const headers = {
        Authorization: getSessionToken(),
        "Content-Type": "application/json",
        "entity-id": getEntityId(),
    };

    const {
        isPending: cancelCompanyTransferIsPending,
        isSuccess: cancelCompanyTransferIsSuccess,
        mutate: cancelCompanyTransferMutate,
    } = useCustomMutation({
        mutationFn: (request: CancelTransfersRequest) =>
            cancelCompanyTransfer(request, headers),
        mutationKey: ["cancelCompanyTransfer"],
    });

    const handleProceedWithCancel = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        await cancelCompanyTransferMutate({ transferIds: [transferId] });
    };

    const { transactions } = useGetCompanyTransfers({ id: transferId });
    const { invalidateQueries } = useInvalidateQuery();

    useEffect(() => {
        if (cancelCompanyTransferIsSuccess) {
            invalidateQueries(QueryKey.CompanyTransfers);
            onClose();
        }
    }, [cancelCompanyTransferIsSuccess, invalidateQueries, onClose]);

    const transaction = transactions?.[0];

    // Find the related agreement, network and token
    const { agreements } = useGetCompanyAgreements({
        id: transaction?.agreementId,
        limit: 1,
    });
    const agreement = agreements?.[0];
    const network = networks.find((n) => n.id === agreement?.networkId);
    const token = tokens.find(
        (t) =>
            t.address.toUpperCase() === agreement?.token.toUpperCase() &&
            t.networkId === agreement?.networkId
    );

    const item = items.find((item) => agreement?.items?.includes(item.id));
    const isValidTransaction =
        transaction && network && token && agreement && item;

    if (!isValidTransaction) {
        return <></>;
    }

    const amountInvoicedText = transaction.usd
        ? `${toDollar(transaction.amount)}${
              token.symbol ? ` in ${token.symbol}` : ``
          }`
        : token.decimals === 0 && !token.symbol
          ? ``
          : `${toCoin(
                Number(formatUnits(String(transaction.amount), token.decimals))
            )} ${token.symbol}`;
    const {
        date: forDate,
        time: forTime,
        zone: forZone,
    } = getDateTimeFromSeconds(transaction.billDate, true);
    const dateDueLabel = `${forDate} ${forTime} ${forZone}`;
    return (
        <S.CancelTransactionForm onSubmit={handleProceedWithCancel}>
            <section>
                <h2>Details</h2>
                <dl>
                    <div>
                        <dt>Amount</dt>
                        <dd>{amountInvoicedText}</dd>
                    </div>
                    {transaction.invoiceId && (
                        <div>
                            <dt>Invoice</dt>
                            <dd>{transaction.invoiceId}</dd>
                        </div>
                    )}

                    <div>
                        <dt>Sender</dt>
                        <dd>
                            {agreement.sender.wallet}{" "}
                            {agreement.sender.email &&
                                `(${agreement.sender.email})`}{" "}
                        </dd>
                    </div>
                    <div>
                        <dt>Receiver</dt>
                        <dd>
                            {transaction.receiver.wallet}{" "}
                            {transaction.receiver.email &&
                                `(${transaction.receiver.email})`}{" "}
                        </dd>
                    </div>
                    <div>
                        <dt>Item</dt>
                        <dd>{item.name}</dd>
                    </div>
                    <div>
                        <dt>Network</dt>
                        <dd>{firstToUpper(network.name)}</dd>
                    </div>
                    <div>
                        <dt>Due date</dt>
                        <dd>{dateDueLabel}</dd>
                    </div>
                </dl>
            </section>
            <S.Buttons>
                {cancelCompanyTransferIsPending ? (
                    <>
                        <Button disabled>Canceling transaction...</Button>
                        <Button
                            variant={ButtonVariants.NeutralOutlined}
                            type="button"
                            disabled
                        >
                            Exit without canceling
                        </Button>
                    </>
                ) : (
                    <>
                        <Button type="submit">Proceed with cancelation</Button>
                        <Button
                            variant={ButtonVariants.NeutralOutlined}
                            type="button"
                            onClick={onClose}
                        >
                            Exit without canceling
                        </Button>
                    </>
                )}
            </S.Buttons>
        </S.CancelTransactionForm>
    );
};

export default CancelTransactionForm;
