import styled, { css } from "styled-components";
import { colorsDeprecated as c } from "global-style";
import { NavLink } from "react-router-dom";
import colors from "theme/colors";

interface AnchorProps {
    disabled: boolean;
    $underlined: boolean;
    $inheritColor: boolean;
    $block: boolean;
    $nowrap: boolean;
    $icon: boolean;
}

const iconAbsolute = css`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-block: auto;
    margin-inline: 0;
`;

const noWrap = css<AnchorProps>`
    display: ${({ $block }) => ($block ? `block` : `inline-block`)};
    position: relative;
    overflow: clip;
    width: max-content;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${({ $icon }) => ($icon ? `padding-right: 1.25rem;` : ``)}
`;

const AnchorStyle = css<AnchorProps>`
    text-decoration: ${({ $underlined }) =>
        $underlined ? "underline" : "none"};
    color: ${({ disabled, $inheritColor }) =>
        disabled ? c.disabled : $inheritColor ? `inherit` : colors.primary};
    ${({ $block }) => ($block ? `display: block;` : ``)}
    ${({ $nowrap }) => ($nowrap ? noWrap : ``)}
    ${({ $icon, $block }) =>
        $icon
            ? css`
                  align-items: center;
                  vertical-align: bottom;
                  ${$block
                      ? css`
                            display: flex;
                            gap: 0.5rem;
                        `
                      : css`
                            display: inline-flex;
                            gap: 0.25rem;
                            margin-inline-end: 0.25rem;
                        `}
              `
            : ``}
`;

export const Anchor = styled.a<AnchorProps>`
    ${AnchorStyle}
`;

export const InternalAnchor = styled(NavLink)<AnchorProps>`
    ${AnchorStyle}
`;

export const Icon = styled.svg<{ $nowrap: boolean }>`
    height: 1em;
    vertical-align: middle;
    ${({ $nowrap }) => ($nowrap ? iconAbsolute : ``)}
`;

export const Path = styled.path`
    fill: ${colors.primary};
`;
