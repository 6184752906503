import { useMemo } from "react";
import { getCustomerData } from "api";
import { CustomerData } from "customer/types";
import { useSession } from "context/Session";
import { useWalletConnected } from "context/Wallet/WalletConnected";
import { useCustomQuery } from "hooks/useCustomQuery";
import { useExchangableTokens } from "hooks/useExchangableTokens";

const GET_CUSTOMER_DATA_QUERY_KEY = `getCustomerData`;

const useGetCustomerData = () => {
    const { walletConnected, networkConnected } = useWalletConnected();
    const { getSessionToken } = useSession();

    // [ ] Use `useAuthQuery` here instead of `useCustomQuery`?
    const {
        data: rawData,
        isError,
        isLoading,
        isPending,
        refetch: getCustomerDataRefetch,
    } = useCustomQuery({
        queryKey: [
            GET_CUSTOMER_DATA_QUERY_KEY,
            walletConnected.proxyFor || walletConnected.address,
            networkConnected?.id || 0,
        ],
        queryFn: () =>
            getCustomerData(
                walletConnected.proxyFor || walletConnected.address,
                networkConnected?.id || 0, // [ ] Handle this fall back case
                {
                    "Content-Type": "application/json",
                    Authorization: getSessionToken(),
                    address: walletConnected.address,
                }
            ),
    });

    const { exchangableTokens } = useExchangableTokens(rawData?.tokens);

    // Combine paymentHistory and upcomingPayments
    const data: CustomerData | undefined = useMemo(
        () =>
            rawData
                ? {
                      ...rawData,
                      transactions:
                          rawData?.transactions.paymentHistory.concat(
                              rawData?.transactions.upcomingPayments
                          ) || [],
                      tokens: exchangableTokens,
                  }
                : undefined,
        [rawData, exchangableTokens]
    );

    return {
        data,
        isLoading: isLoading || isPending,
        isError: isError,
        getCustomerDataRefetch,
    };
};

export default useGetCustomerData;
