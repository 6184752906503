import { getCompanyConfig } from "api";
import { useEns } from "context/EnsProvider";
import { useAuthQuery } from "hooks/useAuthQuery";
import { useEffect, useMemo } from "react";
import { useUser } from "context/User";
import { QueryKey } from "api/types";

export const useGetCompanyConfig = () => {
    const { getEntityId, getSessionToken } = useUser();
    const { lookupEnsRecords } = useEns();
    const queryKey = [QueryKey.CompanyConfig];

    const {
        data: getCompanyConfigData,
        isError: getCompanyConfigIsError,
        isLoading: getCompanyConfigIsLoading,
        dataUpdatedAt: getCompanyConfigDataUpdatedAt,
        refetch: getCompanyConfigRefetch,
        isFetching: getCompanyConfigIsFetching,
    } = useAuthQuery({
        queryKey: queryKey,
        queryFn: () =>
            getCompanyConfig(
                getEntityId(),
                {},
                { Authorization: getSessionToken() }
            ),
    });

    const entities = useMemo(
        () => getCompanyConfigData?.entities || [],
        [getCompanyConfigData?.entities]
    );
    const apiKeys = useMemo(
        () => getCompanyConfigData?.apiKeys || [],
        [getCompanyConfigData?.apiKeys]
    );
    const contracts = useMemo(
        () => getCompanyConfigData?.contracts || [],
        [getCompanyConfigData?.contracts]
    );

    const userEntity = useMemo(
        () => entities.find((entity) => entity.entityId === getEntityId()),
        [entities, getEntityId]
    );

    // Lookup ENS records for all wallet addresses that were returned from the "/config" endpoint
    useEffect(() => {
        let configAddresses;

        // Find and format wallet addresses from Contract[] objects
        configAddresses = contracts.reduce<string[]>(
            (addresses, { owner, certifiedSigner }) => {
                addresses.push(owner);
                if (certifiedSigner) addresses.push(certifiedSigner);

                return addresses;
            },
            []
        );

        // Find and format wallet addresses from Entity[] objects
        configAddresses = entities.reduce<string[]>(
            (addresses, { inboundTreasuries }) => {
                if (inboundTreasuries) {
                    Object.values(inboundTreasuries).forEach((treasury) => {
                        addresses.push(...treasury);
                    });
                }
                return addresses;
            },
            configAddresses
        );

        // Lookup "config" addresses
        lookupEnsRecords(configAddresses);
    }, [contracts, entities, lookupEnsRecords]);

    return {
        config: {
            entities,
            apiKeys,
            contracts,
            userEntity,
        },
        getCompanyConfigIsError,
        getCompanyConfigIsLoading,
        getCompanyConfigDataUpdatedAt,
        getCompanyConfigRefetch,
        getCompanyConfigIsFetching,
    };
};
