import { useState } from "react";
import { Link } from "react-router-dom";
import { apiServerUrl } from "default-variables";
import { useNotificationQueue } from "context/NotificationQueue";
import Loading from "components/Loading";
import { regex } from "utils/regex";
import Anchor from "components/Anchor";
import { NotificationType } from "components/Notification";
import { getErrorMessage } from "utils/errors";
import Button, { ButtonSizes } from "components/Button";
import Form from "components/Form";
import Title from "components/Title";
import { Spacing } from "theme/spacing";
import Paragraph from "components/Paragraph";
import Field from "components/Field";
import Input, { InputSizes } from "components/Input";
import Hint, { HintType } from "components/Hint";

interface RequestResetFormComponentProps {
    onSubmit: (event: React.FormEvent<HTMLFormElement>, email: string) => void;
    loading: boolean;
}

const RequestResetFormComponent = ({
    onSubmit,
    loading,
}: RequestResetFormComponentProps) => {
    const [email, setEmail] = useState({ value: ``, error: false });

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail({ value: event.target.value, error: false });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (!email.value.match(regex.email)) {
            setEmail({ ...email, error: true });
            return;
        }
        onSubmit(event, email.value);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Title level="h2" spacing={[Spacing.sm]}>
                Request a new password
            </Title>
            <Paragraph spacing={[Spacing.sm]}>
                Please enter the email used for your Loop account. We will send
                you a link to reset your password.
            </Paragraph>
            <Field>
                <Input
                    placeholder="Email"
                    type="email"
                    inputSize={InputSizes.Large}
                    value={email.value}
                    onChange={handleEmailChange}
                    aria-invalid={email.error}
                />
                {email.error && (
                    <Hint type={HintType.Error}>
                        Must be a valid email address
                    </Hint>
                )}
            </Field>
            <Field>
                <Button
                    type="submit"
                    loading={loading}
                    full
                    size={ButtonSizes.Large}
                >
                    Reset Password
                </Button>
            </Field>
            <Link to="/login" style={{ textDecoration: "none" }}>
                Back to Login
            </Link>
        </Form>
    );
};

const ConfirmationPage = () => {
    return (
        <>
            <Title level="h2" spacing={[Spacing.sm]}>
                A new password reset link has been sent.
            </Title>
            <Paragraph spacing={[Spacing.sm]}>
                Please check your email and follow the instructions.
            </Paragraph>
        </>
    );
};

const RequestResetForm = () => {
    const { addNotification } = useNotificationQueue();
    const [state, setState] = useState({
        page: 0,
        loading: false,
    });

    const { page, loading } = state;

    const handleSubmit = async (
        _event: React.FormEvent<HTMLFormElement>,
        email: string
    ) => {
        setState({ ...state, loading: true });

        let res, data;
        try {
            res = await fetch(`${apiServerUrl}/v1/user/forgot-password`, {
                method: `POST`,
                body: JSON.stringify({
                    email,
                }),
            });

            data = await res.json();

            if (!res.ok) {
                throw data;
            }
        } catch (error: any) {
            const errorMsg =
                error?.message === `not_active_user` ? (
                    <>
                        {getErrorMessage(error)}
                        <br />
                        Email{" "}
                        <Anchor
                            href={`mailto:${
                                import.meta.env.VITE_EMAIL_SUPPORT
                            }`}
                        >
                            {import.meta.env.VITE_EMAIL_SUPPORT}
                        </Anchor>{" "}
                        to reset your activation hash.
                    </>
                ) : (
                    getErrorMessage(error)
                );
            addNotification({ msg: errorMsg, type: NotificationType.ERROR });
            return;
        } finally {
            setState({
                ...state,
                loading: false,
            });
        }

        setState({
            ...state,
            page: res && res.ok ? page + 1 : page,
        });
    };

    if (page === 0) {
        return (
            <>
                {state.loading && <Loading />}
                <RequestResetFormComponent
                    onSubmit={handleSubmit}
                    loading={loading}
                />
            </>
        );
    } else {
        return <ConfirmationPage />;
    }
};

export default RequestResetForm;
