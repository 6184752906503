import styled from "styled-components";
import spacing from "theme/spacing";
import Section from "components/Section";

export const ErrorMessage = styled(Section)`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${spacing.sm};
    text-align: center;
`;
