import { useEffect, useState } from "react";
import { Contract, Signer, parseUnits } from "ethers";
import IWethAbi from "abis/abi/IWeth.json";
import { nativeTokens } from "default-variables";
import { getWrapperSymbols, getNativeTokenBySymbol } from "utils/tokens";
import { TokenWithoutRequiredAggregator } from "types/common";

// A Native token with details about it's wrapper
export interface WrapperToken extends TokenWithoutRequiredAggregator {
    wrapsTo: string;
}

export const wrapNativeToken = async (
    tokenAddress: string,
    signer: Signer,
    value: any,
    fromSymbol: string,
    toSymbol: string,
    skipAwait: boolean = false,
    signedHandler: (() => void) | null = null
): Promise<any> => {
    const supportedWrappers = getWrapperSymbols();
    if (!supportedWrappers.includes(toSymbol.toUpperCase())) {
        return Promise.reject(
            `${fromSymbol.toUpperCase()} cannot be wrapped to ${toSymbol.toUpperCase()}}`
        );
    }

    let response;
    try {
        const wrapContract = new Contract(
            tokenAddress,
            IWethAbi, // IWethAbi deposit function is identical for IWmaticAbi
            signer
        );

        const decimals = getNativeTokenBySymbol(fromSymbol)?.decimals;
        if (!decimals)
            throw new Error(
                `There was a problem formatting ${value} ${fromSymbol.toUpperCase()} to ${toSymbol.toUpperCase()}`
            );

        response = await wrapContract.deposit({
            value: parseUnits(value, decimals),
        });
    } catch (error) {
        return Promise.reject(
            `You wallet rejected the transaction to wrap ${toSymbol.toUpperCase()}`
        );
    }

    if (signedHandler) signedHandler();
    if (skipAwait) return Promise.resolve(response);

    // Get the receipt
    try {
        const receipt = await response.wait();
        if (receipt.status === 0) {
            return Promise.reject(
                `There was a problem wrapping to ${toSymbol.toUpperCase()}`
            );
        }
        return Promise.resolve(receipt);
    } catch (error) {
        return Promise.reject(
            `There was a problem wrapping to ${toSymbol.toUpperCase()}`
        );
    }
};

const useWrappableTokens = <T extends TokenWithoutRequiredAggregator>(
    tokensThatMightBeWrappers: T[] | undefined
) => {
    const [wrappableTokens, setWrappableTokens] = useState<WrapperToken[]>([]);

    useEffect(() => {
        const searchableTokens = tokensThatMightBeWrappers || [];
        setWrappableTokens(
            searchableTokens.reduce<WrapperToken[]>(
                // Loop through each baseToken and check if there is a `wraps` object that matches both the symbol and networkId of the possibleWrapper.
                //      If there is, add the possibleWrapper to the wrappables array.
                //      If there isn't, return the wrappables array
                (wrappables, possibleWrapper) => {
                    return nativeTokens.reduce<WrapperToken[]>(
                        (wrappables, { networks, ...baseToken }) => {
                            const tokenWrapper = networks?.find(
                                ({ wrapper, networkId }) =>
                                    wrapper === possibleWrapper.symbol &&
                                    networkId === possibleWrapper.networkId
                            );
                            if (tokenWrapper) {
                                return [
                                    ...wrappables,
                                    {
                                        ...possibleWrapper,
                                        ...baseToken,
                                        wrapsTo: possibleWrapper.symbol,
                                    },
                                ];
                            }
                            return wrappables;
                        },
                        wrappables
                    );
                },
                []
            )
        );
    }, [tokensThatMightBeWrappers]);

    return wrappableTokens;
};

export default useWrappableTokens;
