import styled, { css } from "styled-components";
import { BadgeProps } from ".";
import { Density } from "types/common-enums";

const backgroundColorForVariant = {
    blue: "#CFE5FF",
    purple: "#E2DFFF",
    green: "#BFFFDA",
    yellow: "#f4d316",
    orange: "#FFDF99",
    red: "#FFDAD6",
    gray: "#E4E1EC",
};

enum textColorForVariant {
    blue = "#004A78",
    purple = "#1E00A5",
    green = "#005235",
    yellow = "#635E02",
    orange = "#594400",
    red = "#BA1A1A",
    gray = "#47464F",
}

export const Badge = styled.span<BadgeProps>`
    ${({ density }) =>
        density === Density.Compact
            ? css`
                  padding: 0.125rem 0.25rem;
                  font-size: 0.75rem;
              `
            : density === Density.Default
            ? css`
                  padding: 0.3125rem 0.5rem;
                  font-size: 0.75rem;
              `
            : css`
                  padding: 0.333rem 0.5rem;
              `}
    line-height: 1;
    border-radius: 0.25rem;
    display: inline-block;
    vertical-align: text-bottom;
    text-align: center;
    background-color: ${({ variant }) => {
        return backgroundColorForVariant[variant ?? "blue"];
    }};
    color: ${({ variant }) => {
        return textColorForVariant[variant ?? "blue"];
    }};
    ${({ uppercase }) => (uppercase ? `text-transform: uppercase;` : ``)}
    ${({ border, variant }) =>
        border && variant
            ? `border: 1px solid ${textColorForVariant[variant]};`
            : ``}
`;
