import { CSSProperties, ComponentType, ReactNode, memo } from "react";
import styled, {
    isStyledComponent,
    FlattenInterpolation,
    StyledComponent,
    ThemeProps,
} from "styled-components";

/*
The `style` prop applied to the wrapped component can be of type:

    StyledComponent<any, any>:
        styled.div`
            padding-block: 1rem;
            font-weight: 700;
        `

    Partial<CSSProperties>:
        {
            paddingBlock: 1rem;
            fontWeight: 700;
        }

    FlattenInterpolation<ThemeProps<any>>:
        css`
            padding-block: 1rem;
            font-weight: 700;
        `
*/
export type StyledComponentOrProperties =
    | StyledComponent<any, any>
    | Partial<CSSProperties>
    | FlattenInterpolation<ThemeProps<any>>;

interface StyleOrStyledComponentProps {
    style?: StyledComponentOrProperties;
    children: ReactNode;
    [key: string]: any;
}

const withStyledComponentOrProperties = (
    WrappedComponent: ComponentType<any>
) => {
    return memo(
        ({ style, children, ...rest }: StyleOrStyledComponentProps) => {
            if (isStyledComponent(style)) {
                const StyledWrapper = style;
                return (
                    <StyledWrapper>
                        <WrappedComponent {...rest}>
                            {children}
                        </WrappedComponent>
                    </StyledWrapper>
                );
            }

            if (Array.isArray(style)) {
                const StyledWrappedComponent = styled(WrappedComponent)`
                    ${style}
                `;
                return (
                    <StyledWrappedComponent {...rest}>
                        {children}
                    </StyledWrappedComponent>
                );
            }

            return (
                <WrappedComponent style={style as CSSProperties} {...rest}>
                    {children}
                </WrappedComponent>
            );
        },
        (prevProps, nextProps) => {
            return (
                JSON.stringify([prevProps.style, prevProps.spacing]) ===
                    JSON.stringify([nextProps.style, nextProps.spacing]) &&
                prevProps.children === nextProps.children
            );
        }
    );
};

export default withStyledComponentOrProperties;
