import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";
import Spinner from "components/Spinner";

export const TokenAllowance = styled.span<{
    disabled: boolean;
}>`
    display: inline-flex;
    align-items: center;
    gap: 0.333rem;
    color: ${({ disabled }) => (disabled ? c.onDisabled : `inherit`)};
    max-width: 100%;
    overflow-x: clip;
`;

export const LoadingAllowance = styled(Spinner)`
    width: 1.4rem;
    height: 1.4rem;
`;

export const TokenSymbol = styled.label`
    pointer-events: none;
`;
