import { HeadersObject, get, patch, post } from "api/rest";
import { GetCheckoutResponse } from "api/types/checkout";
import {
    Company,
    CompanyAuthorizationHeaders,
    CreateItemRequest,
    EntityInboundTreasuriesByNetworkId,
    GeneralItemsResponse,
    GetCompanyAgreementsHttpRequest,
    GetCompanyConfigHttpRequest,
    GetCompanyConfigResponse,
    GetCompanyExternalSubscriptionResponse,
    GetCompanyItemsResponse,
    GetCompanyTransfersHttpRequest,
    GetCompanyTransfersResponse,
    PatchTransferResponse,
    PatchTransfersRequest,
    UpdateItemRequest,
} from "company/types";
import {
    CreateInvoicingPaymentRequest,
    GetInvoiceEntityResponse,
    InvoicingPaymentResponse,
    PaginatedPaymentViewResponse,
    PostInvoicePaymentResponse,
} from "api/types/invoice";
import { GetInvoicePaymentsHttpRequest } from "invoice/types";
import {
    GetCustomerDataResponse,
    PatchCustomerCancelAgreementsResponse,
} from "api/types/customer";
import { PaginatedCompanyAgreementResponse } from "api/types/company";

export enum ApiErrorType {
    // Confirmation code
    ConfirmationCodeInvalid = "confirmation_code_invalid",
    ConfirmationCodeExpired = "confirmation_code_expired",
    ConfirmationCodeInvalidEmail = "confirmation_code_invalid_email",

    // Entities
    EntityCreateFailed = "entity_create_failed",

    // Items
    ItemsCreateFailed = "items_create_failed",
    ItemsMissingPayload = "items_missing_payload",

    // User
    UserAlreadyExist = "user_already_exist",
    UserCreateError = "user_create_error",

    // Checkout
    CheckoutTryAgainLater = "checkout_try_again_later",

    // Misc
    SmartContractDeployFailed = "smart_contract_deploy_failed",
    InboundTreasuryAddressesInvalid = "inbound_treasury_addresses_invalid",
    UnknownError = "unknown_error",
}

export const apiErrorTypesForDisplay = {
    [ApiErrorType.ConfirmationCodeInvalid]: "Confirmation code is invalid",
    [ApiErrorType.ConfirmationCodeExpired]: "Confirmation code is expired",
    [ApiErrorType.ConfirmationCodeInvalidEmail]: "Not a valid email",
    [ApiErrorType.EntityCreateFailed]: "Failed to create the entity",
    [ApiErrorType.ItemsCreateFailed]: "Failed to create the item",
    [ApiErrorType.ItemsMissingPayload]: "Missing item details",
    [ApiErrorType.UserAlreadyExist]: "This user already exists",
    [ApiErrorType.UserCreateError]: "Filed to create the user",
    [ApiErrorType.SmartContractDeployFailed]:
        "Failed to deploy the smart contract",
    [ApiErrorType.InboundTreasuryAddressesInvalid]:
        "The receiving address is invalid",
    [ApiErrorType.UnknownError]: "Something went wrong",
    [ApiErrorType.CheckoutTryAgainLater]:
        "A previous checkout attempt is still in progress. Please wait a minute then refresh to try again.",
};

export type CompanyApiError = {
    code: number;
    message: ApiErrorType;
};

export function getErrorForDisplay(error: any) {
    // Default
    let errorForDisplay = apiErrorTypesForDisplay[ApiErrorType.UnknownError];

    if ("message" in error) {
        const specificError = error as CompanyApiError;
        let potentialErrorForDisplay =
            apiErrorTypesForDisplay[specificError.message];
        if (potentialErrorForDisplay) {
            errorForDisplay = potentialErrorForDisplay;
        }
    }

    return errorForDisplay;
}

// Checkout specific endpoints

// Get and verify coupons against entityId/itemIds
export async function getVerifyCoupons(request: GetVerifyCouponsRequest) {
    const serializedRequest = {
        ...request,
        couponCodes: request.couponCodes.join(","),
        itemIds: request.itemIds.join(","),
    };

    return await get<GetVerifyCouponsResponse, ApiError>(
        `api/v1/checkout/verify-coupons`,
        serializedRequest
    );
}

export async function getCheckoutItem(request: GetCheckoutItemRequest) {
    const url = `checkout/${request.entityId}/${request.baseItemNameId}`;

    const serializedRequest: {
        items?: string;
        externalSubscriptionId?: string;
        discount?: string;
        couponCodeId?: string;
        externalCustomerId?: string;
    } = {};

    if (request.items) {
        serializedRequest.items = request.items.join(",");
    }

    if (request.externalSubscriptionId !== undefined) {
        serializedRequest.externalSubscriptionId =
            request.externalSubscriptionId;
    }

    if (request.externalCustomerId !== undefined) {
        serializedRequest.externalCustomerId = request.externalCustomerId;
    }

    if (request.discount) {
        serializedRequest.discount = request.discount.toString();
    }

    if (request.couponCodeId) {
        serializedRequest.couponCodeId = request.couponCodeId;
    }

    return await get<GetCheckoutResponse, ApiError>(url, serializedRequest);
}

export async function getCheckoutInvoice(request: GetCheckoutInvoiceRequest) {
    const url = `checkout/${request.entityId}`;

    const serializedRequest: {
        invoiceId?: string;
        invoiceAmount?: string;
    } = {};

    if (request.invoiceId) {
        serializedRequest.invoiceId = request.invoiceId;
    }
    if (request.invoiceAmount) {
        serializedRequest.invoiceAmount = request.invoiceAmount.toString();
    }

    return await get<GetCheckoutResponse, GetCheckoutResponseError>(
        url,
        serializedRequest
    );
}

export async function getCheckoutWalletAddressStatus(
    networkHexId: string,
    contractAddress: string,
    address: string,
    itemId: string
) {
    const url = `api/v1/checkout/status/${networkHexId}/${contractAddress}/${address}/${itemId}`;

    return await get<GetPendingCheckoutStatusResponse, ApiError>(url);
}

export async function postAgreeToItems(
    address: string,
    request: PostAgreementCheckoutHttpRequest,
    headers: PostAgreementCheckoutHttpHeaders
) {
    const url = `api/v1/checkout/${address}`;

    return await post<CheckoutAgreementResponse, PostAgreementCheckoutError>(
        url,
        request,
        headers
    );
}

export async function getInvoiceEntity(entityAlias: string) {
    const url = `api/v1/invoice/config/${entityAlias}`;

    const { data, response } = await get<GetInvoiceEntityResponse, ApiError>(
        url
    );

    // When used by react-query, we need to throw an error for the hook to catch
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

export async function postInvoicePayment(
    request: CreateInvoicingPaymentRequest,
    headers: HeadersObject = {
        "Content-Type": "application/json",
    }
): Promise<PostInvoicePaymentResponse> {
    const url = `api/v1/invoice/`;

    const { data, response } = await post<InvoicingPaymentResponse, ApiError>(
        url,
        request,
        headers
    );

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    if (!data) {
        return { success: false };
    }

    return {
        success: true,
        ...data,
    };
}

// Company specific endpoint
export async function getCompanyItems(
    entityId: string,
    headers: HeadersObject
) {
    const url = `api/v1/company/${entityId}/items`;

    const { data, response } = await get<GetCompanyItemsResponse, ApiError>(
        url,
        undefined,
        headers
    );

    // When used by react-query, we need to throw an error for the hook to catch
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

export async function getCompanyAgreements(
    entityId: string,
    request: GetCompanyAgreementsHttpRequest,
    headers: HeadersObject
) {
    const url = `api/v1/company/${entityId}/agreements`;

    const serializedRequest: QueryParams<GetCompanyAgreementsHttpRequest> = {};

    // Pagination
    if (request.page !== undefined)
        serializedRequest.page = request.page.toString();
    if (request.limit !== undefined)
        serializedRequest.limit = request.limit.toString();
    if (request.sortBy) serializedRequest.sortBy = request.sortBy;
    if (request.sortDir) serializedRequest.sortDir = request.sortDir;
    // Filters
    if (request.id) serializedRequest.id = request.id;
    if (request.statuses) serializedRequest.statuses = request.statuses;
    if (request.from) serializedRequest.from = request.from;
    if (request.itemIds) serializedRequest.itemIds = request.itemIds;
    if (request.itemTypes) serializedRequest.itemTypes = request.itemTypes;
    if (request.entities) serializedRequest.entities = request.entities;
    if (request.networks) serializedRequest.networks = request.networks;

    const { data, response } = await get<
        PaginatedCompanyAgreementResponse,
        ApiError
    >(url, serializedRequest, headers);

    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return data;
}

export async function getCompanyTransfers(
    entityId: string,
    request: GetCompanyTransfersHttpRequest,
    headers: HeadersObject
) {
    const url = `api/v1/company/${entityId}/transfers`;

    const serializedRequest: QueryParams<GetCompanyTransfersHttpRequest> = {};

    // Pagination
    if (request.page !== undefined)
        serializedRequest.page = request.page.toString();
    if (request.limit !== undefined)
        serializedRequest.limit = request.limit.toString();
    if (request.sortBy) serializedRequest.sortBy = request.sortBy;
    if (request.sortDir) serializedRequest.sortDir = request.sortDir;
    // Filters
    if (request.id) serializedRequest.id = request.id;
    if (request.type) serializedRequest.type = request.type;
    if (request.date !== undefined)
        serializedRequest.date = request.date.toString();
    if (request.search) serializedRequest.search = request.search;
    if (request.entities) serializedRequest.entities = request.entities;
    if (request.networks) serializedRequest.networks = request.networks;

    const { data, response } = await get<GetCompanyTransfersResponse, ApiError>(
        url,
        serializedRequest,
        headers
    );

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

export async function getCompanyConfig(
    entityId: string,
    request: GetCompanyConfigHttpRequest,
    headers: HeadersObject
) {
    const url = `api/v1/company/${entityId}/config`;

    const { data, response } = await get<GetCompanyConfigResponse, ApiError>(
        url,
        undefined,
        headers
    );

    // When used by react-query, we need to throw an error for the hook to catch
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

export async function getCompanyExternalSubscriptionDetails(
    entityId: string,
    externalSubscriptionId: string,
    headers: HeadersObject
) {
    const url = `api/v1/company/${entityId}/external-subscriptions/${externalSubscriptionId}`;

    return await get<GetCompanyExternalSubscriptionResponse, ApiError>(
        url,
        undefined,
        headers
    );
}

export async function patchCompanyTransfers(
    request: PatchTransfersRequest,
    headers: CompanyAuthorizationHeaders
) {
    const url = `api/v1/company/transfers`;

    return await patch<PatchTransferResponse, ApiError>(url, request, headers);
}

export async function createCompanyItems(
    request: CreateItemRequest[],
    headers: CompanyAuthorizationHeaders
) {
    const url = `api/v1/company/items`;

    return await post<GeneralItemsResponse, ApiError>(url, request, headers);
}

export async function updatedCompanyItems(
    request: UpdateItemRequest[],
    headers: CompanyAuthorizationHeaders
) {
    const url = `api/v1/company/items`;

    return await patch<GeneralItemsResponse, ApiError>(url, request, headers);
}

export interface CancelTransfersRequest {
    transferIds: string[];
}

export async function cancelCompanyTransfer(
    request: CancelTransfersRequest,
    headers: CompanyAuthorizationHeaders
) {
    const url = `api/v1/company/transfers/cancel`;
    await patch<Company.Transaction, ApiError>(url, request, headers);
}

export async function getInvoiceConfig(
    entityId: string,
    headers: HeadersObject
) {
    const url = `ui/invoice/config/${entityId}`;

    const { data, response } = await get<GetInvoiceEntityResponse, ApiError>(
        url,
        undefined,
        headers
    );

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

export async function getInvoicePayments(
    entityId: string,
    request: GetInvoicePaymentsHttpRequest,
    headers: HeadersObject
) {
    const url = `ui/invoice/entity/${entityId}/payments`;

    const serializedRequest: QueryParams<GetInvoicePaymentsHttpRequest> = {};

    if (request.page !== undefined)
        serializedRequest.page = request.page.toString();
    if (request.limit !== undefined)
        serializedRequest.limit = request.limit.toString();
    if (request.sortBy) serializedRequest.sortBy = request.sortBy;
    if (request.sortDir) serializedRequest.sortDir = request.sortDir;

    const { data, response } = await get<
        PaginatedPaymentViewResponse,
        ApiError
    >(url, serializedRequest, headers);

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

// Customer specific endpoints
export async function getCustomerData(
    address: string,
    networkHex: number,
    headers: HeadersObject
) {
    const url = `api/v1/customer/transactions/${address}`;

    const { data, response } = await get<GetCustomerDataResponse, ApiError>(
        url,
        { networkId: networkHex.toString() },
        headers
    );

    if (!response.ok) throw new Error(response.statusText);
    return data;
}

export async function patchCustomerCancelAgreements(
    address: string,
    agreementId: string | string[],
    headers: HeadersObject
) {
    const agreementIds = Array.isArray(agreementId)
        ? agreementId
        : [agreementId];

    const url = `api/v1/customer/agreements/${address}`;

    return await patch<PatchCustomerCancelAgreementsResponse, ApiError>(
        url,
        {
            agreementIds,
        },
        headers
    );
}

/* 
    Common
*/

type QueryParams<T> = {
    [K in keyof T]?: string;
};

export interface ExchangeRateDetails {
    currency: string;
    rate: number;
    updated: number;
    provider: string;
}

export interface TokenExchangeDetailsResponse {
    name: string;
    logoUrl: string;
    symbol: string;
    decimals: number;
    address: string;
    networkId: number;
    exchangeRates: ExchangeRateDetails[];
}

// Tokens
export interface GeneralTokenDetailsResponse {
    name: string;
    active: boolean;
    logoUrl: string;
    symbol: string;
    decimals: number;
    address: string;
    aggregatorAddress: string;
    networkId: number;
    exchangeRates: ExchangeRateDetails[];
}

export interface GetTokensMetadataResponse {
    tokens: GeneralTokenDetailsResponse[];
}

export async function getTokensMetadata() {
    const url = `api/v1/tokens`;
    const { data, response } = await get<GetTokensMetadataResponse>(url);

    // When used by react-query, we need to throw an error for the hook to catch
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

// Blockchain Networks
export interface CommonBlockchainNetworkResponse {
    id: number;
    name: string;
    hexId: string;
    active: boolean;
}

export interface GetBlockchainNetworksResponse {
    networks: CommonBlockchainNetworkResponse[];
}

export async function getNetworks() {
    const url = `api/v1/networks`;
    const { data, response } = await get<GetBlockchainNetworksResponse>(url);
    // When used by react-query, we need to throw an error for the hook to catch
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

export interface GetSelfServeAcceptedTokensResponse {
    acceptedTokensByNetwork: Record<number, string[]>;
}
export async function getSelfServeAcceptedTokens() {
    const url = `api/v1/self-serve/accepted-tokens`;
    const { response, data } =
        await get<GetSelfServeAcceptedTokensResponse>(url);

    // When used by react-query, we need to throw an error for the hook to catch
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return data;
}

export interface CreateConfirmationCode {
    email: string;
}

export interface PostSelfServeEmailConfirmationCodeResponse {
    success: boolean;
}

export async function postSelfServeEmailConfirmationCode(
    request: CreateConfirmationCode
) {
    const url = `api/v1/self-serve/email-confirmation-code`;

    const { response, data } = await post<
        PostSelfServeEmailConfirmationCodeResponse,
        ApiError
    >(url, request);

    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return data;
}

export interface SelfServeNewEntityConfig {
    companyName: string;
    inboundTreasuries: EntityInboundTreasuriesByNetworkId;
    url: string;
    replyTo: string;
}

export interface SelfServeNewUser {
    email: string;
    password: string;
}
export interface PostSelfServeCreateAccountRequest {
    code: number;
    user: SelfServeNewUser;
    entity: SelfServeNewEntityConfig;
    items: CreateItemRequest[];
}

export type PendingTransactionDetails = {
    networkId: number;
    transactionHash: string;
};

export interface PostSelfServeCreateAccountResponse {
    pendingTransactionsDetails: PendingTransactionDetails[];
    items: Company.Item[];
    entity: Company.Entity;
    auth: {
        token: string;
        roles: string[];
    };
}

export async function postSelfServeCreateAccount(
    request: PostSelfServeCreateAccountRequest
) {
    const url = `api/v1/self-serve/create-account`;

    const { response, data, error } = await post<
        PostSelfServeCreateAccountResponse,
        CompanyApiError
    >(url, request);

    if (!response.ok && error) {
        throw error;
        // return Promise.reject(error);
    }

    return data;
}
