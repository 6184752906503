import styled from "styled-components";
import { SpacingTrack } from "theme/spacing";
import { fontSizes, fontWeight } from "theme/typography";
import { withSpacing } from "utils/styling";

export const Paragraph = styled.p<{
    $bold: boolean;
    $small: boolean;
    $spacing?: SpacingTrack;
}>`
    font-size: ${({ $small }) => ($small ? fontSizes.sm : fontSizes.md)};
    font-weight: ${({ $bold }) =>
        $bold ? fontWeight.bold : fontWeight.normal};
    margin: 0;

    ${({ $spacing }) => withSpacing($spacing)}
`;
