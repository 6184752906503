import { getValuesFromKeys, getExcludedKeys } from "utils/objects";

// Note a companion definition exists in common.d.ts
export enum AgreementType {
    New = 1,
    Active = 2,
    Canceled = 3,
    One_Time = 4,
    Scheduled_Cancel = 5,
}

export const AgreementCanceledStatuses = [
    AgreementType[AgreementType.Canceled],
    AgreementType[AgreementType.Scheduled_Cancel],
];

export const nonCanceledAgreementStatusNumbers = getValuesFromKeys(
    AgreementType,
    getExcludedKeys(AgreementType, AgreementCanceledStatuses)
);

export enum TransferStatus {
    Scheduled = "Scheduled",
    Succeeded = "Succeeded",
    Failed = "Failed",
    Cancelled = "Cancelled",
    Uncollectible = "Uncollectible",
    Pending = "Pending",
    Draft = "Draft",
}

export const UpcomingOrDueTransferStatuses = [
    TransferStatus.Draft,
    TransferStatus.Scheduled,
    TransferStatus.Pending,
] as `${TransferStatus}`[];

export enum ItemType {
    Donation = 1,
    Subscription = 2,
    Loan = 3,
    OutboundPayment = 4,
    Fee = 7,
    Other = 8,
    One_Time = 9,
}

export enum ItemCategoryType {
    Donation = "Donation",
    Subscription = "Subscription",
    Loan = "Loan",
    OutboundPayment = "Outbound Payment",
    Fee = "Fee",
    Other = "Other",
    One_Time = "One Time",
}

export const ItemCategoryTypeOutbound = [ItemCategoryType.OutboundPayment];
export const ItemCategoryTypeInbound = [
    ItemCategoryType.Donation,
    ItemCategoryType.Subscription,
    ItemCategoryType.Fee,
    ItemCategoryType.Loan,
    ItemCategoryType.Other,
    ItemCategoryType.One_Time,
];
// These exist as compliments to the enums above because of the discrepancy with how spaces and underscores translate to enum key names (keep until this gets fixed on the backend)
export const ItemCategoryTypeOutboundKeysAsStrings =
    ItemCategoryTypeOutbound.map((item) =>
        Object.keys(ItemCategoryType).find(
            (key) =>
                ItemCategoryType[key as keyof typeof ItemCategoryType] === item
        )
    ) as string[];
export const ItemCategoryTypeInboundKeysAsStrings = ItemCategoryTypeInbound.map(
    (item) =>
        Object.keys(ItemCategoryType).find(
            (key) =>
                ItemCategoryType[key as keyof typeof ItemCategoryType] === item
        )
) as string[];

export enum SmartContractType {
    Subscription = 1,
    RecurringSwap = 2,
    VariableRate = 3,
}

export enum FrequencyType {
    Hour = "hour",
    Day = "day",
    Week = "week",
    Month = "month",
    Year = "year",
}

export enum CurrencyType {
    USD = "USD",
}

export enum Density {
    Compact = `Compact`,
    Default = `Default`,
    Spacious = `Spacious`,
}
