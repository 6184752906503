import {
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { useQueryProvider } from "context/QueryProvider";
import { useEffect } from "react";

export function useCustomQuery<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData
>(
    options: UseQueryOptions<TQueryFnData, TError, TData>
): UseQueryResult<TData, TError> {
    const { queriesFetchedOnFirstMount } = useQueryProvider();
    const queryKeyString = JSON.stringify(options.queryKey);
    const queryInfo = useQuery<TQueryFnData, TError, TData>({
        ...options,
    });

    const { refetch, isFetching } = queryInfo;

    // Ensure a refetch on first page visit
    useEffect(() => {
        const queryWasFetchedOnFirstMount =
            queriesFetchedOnFirstMount.includes(queryKeyString);

        // We have already fetched the query at least once
        if (queryWasFetchedOnFirstMount) return;

        // We are already fetching (non-cached)
        if (isFetching) {
            queriesFetchedOnFirstMount.push(queryKeyString);
            return;
        }

        // We are not fetching (cached) & query hasn't been fetched at least once
        if (
            !queryWasFetchedOnFirstMount &&
            !isFetching &&
            options.enabled !== false // can be true/false/undefined(default to true)
        ) {
            refetch();
            queriesFetchedOnFirstMount.push(queryKeyString);
        }
    }, [
        isFetching,
        options.enabled,
        options.queryKey,
        queriesFetchedOnFirstMount,
        queryKeyString,
        refetch,
    ]);

    // [ ] All queries should go through this hook and it should be handling errors
    return queryInfo;
}
