import React, { useCallback, useState, useRef } from "react";
import { Company, UpdateTransferDelegatedSigner } from "company/types";
import * as S from "./style";
import Button, { ButtonVariants } from "components/Button";
import TransferBillDateField, {
    TransferBillDateFieldRef,
} from "company/components/transfers/TransferBillDateField";
import { useNotificationQueue } from "context/NotificationQueue";
import { patchCompanyTransfers } from "api";
import { NotificationType } from "components/Notification";
import { useUser } from "context/User";
import { QueryKey } from "api/types";
import useInvalidateQuery from "hooks/useInvalidateQuery";

interface UpdateTransferBillDateFormProps {
    transaction: Company.Transaction;
    onCancel?: () => void;
    onSuccess?: () => void;
}

const UpdateTransferBillDateForm: React.FunctionComponent<
    UpdateTransferBillDateFormProps
> = ({ transaction, onCancel, onSuccess }) => {
    // Hooks
    const transferBillDateRef = useRef<TransferBillDateFieldRef>(null);

    // State
    const [formLoading, setFormLoading] = useState<boolean>(false);

    // Session & Company data
    const { invalidateQueries } = useInvalidateQuery();
    const { getEntityId, getSessionToken } = useUser();

    // Notifications
    const { addNotification } = useNotificationQueue();

    const successHandle = useCallback(async () => {
        await invalidateQueries(QueryKey.CompanyTransfers);
        if (onSuccess) onSuccess();
    }, [invalidateQueries, onSuccess]);

    // RIP: useFetch :sad_face_emoji:
    // Note: Talked with Rocco, we need to consider refactoring the useFetch
    // and/or use a library like react-query for the POST scenario
    const updateBillDate = async () => {
        setFormLoading(true);

        // Get & Validate billDate
        let billDate: number | undefined;
        if (transferBillDateRef.current) {
            billDate = transferBillDateRef.current.billDateForApi;
            if (!transferBillDateRef.current.validate()) return;

            // if data hasn't change, trigger onSuccess
            if (!transferBillDateRef.current.hasChanged) {
                if (onSuccess) onSuccess();
                return;
            }
        }

        if (!billDate) return;

        const patchTransfer: UpdateTransferDelegatedSigner = {
            transferId: transaction.transferId,
            billDate,
        };

        const headers = {
            Authorization: getSessionToken(),
            "entity-id": getEntityId(),
        };

        const { response } = await patchCompanyTransfers(
            [patchTransfer],
            headers
        );

        const successFullResponse = response.ok && response.status === 200;

        if (successFullResponse) {
            await successHandle();
        } else {
            addNotification({
                msg: `There was an error while updating the due date`,
                type: NotificationType.ERROR,
            });
        }

        setFormLoading(false);
    };

    return (
        <S.EditForm onSubmit={updateBillDate}>
            <TransferBillDateField
                disabled={formLoading}
                defaultBillDate={new Date(transaction.billDate * 1000)}
                ref={transferBillDateRef}
            />
            <Button type="submit" loading={formLoading}>
                {formLoading ? "Saving" : "Save"}
            </Button>
            <Button
                variant={ButtonVariants.Anchor}
                type="button"
                disabled={formLoading}
                onClick={onCancel}
            >
                Cancel
            </Button>
        </S.EditForm>
    );
};

export default UpdateTransferBillDateForm;
