import { useCallback, useMemo, useState } from "react";
import {
    AgreementCanceledStatuses,
    Density,
    ItemCategoryType,
    ItemCategoryTypeInbound,
    TransferStatus,
} from "types/common-enums";
import { formatUnits } from "ethers";
import { toCoin, toDollar } from "utils/financial";
import { describeFrequency, getReadableDate } from "utils/dates";
import { toNetworkHex } from "utils/addresses";
import { firstToUpper } from "utils/strings";
import { isStableCoin } from "utils/tokens";
import { isTransactionUpcomingOrDue } from "utils/transactions";
import { patchCustomerCancelAgreements } from "api";
import { useSession } from "context/Session";
import useGetCustomerData from "customer/hooks/useGetCustomerData";
import { useWalletConnected } from "context/Wallet/WalletConnected";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import {
    CustomerAuthorization,
    CustomerAuthorizationType,
} from "customer/types";
import Badge from "components/Badge";

const useAuthorizations = () => {
    const {
        data,
        isLoading: isCustomerDataLoading,
        isError,
        getCustomerDataRefetch,
    } = useGetCustomerData();
    const { getSessionToken } = useSession();
    const { walletConnected } = useWalletConnected();
    const [isCancelAuthorizationsLoading, setIsCancelAuthorizationsLoading] =
        useState(false);

    const authorizations: CustomerAuthorization[] = useMemo(() => {
        if (
            !data?.agreements ||
            !data?.entities ||
            !data?.contracts ||
            !data?.items ||
            !data?.networks ||
            !data?.tokens ||
            !data?.transactions
        )
            return [];

        return data.agreements
            .sort((a, b) => b.createdAt - a.createdAt)
            .reduce<CustomerAuthorization[]>(
                (formattedAuthorizations, agreement) => {
                    const entity = data.entities.find(
                        ({ entityId }) => entityId === agreement.entity
                    );

                    const contract = data.contracts.find(
                        ({ owner, networkId }) =>
                            owner === agreement.entity &&
                            networkId === agreement.networkId
                    );

                    const items = data.items.filter(({ id }) =>
                        agreement.items.includes(id)
                    );

                    const network = data.networks.find(
                        ({ id }) => id === agreement.networkId
                    );

                    const token = data?.tokens.find(
                        ({ address, networkId }) =>
                            address === agreement.token &&
                            networkId === agreement.networkId
                    );

                    if (!entity || !contract || !items || !network || !token)
                        return formattedAuthorizations;

                    const hasInvalidItems = items.some(
                        ({ type }) =>
                            !Object.values(ItemCategoryTypeInbound).includes(
                                type as ItemCategoryType
                            )
                    );
                    if (hasInvalidItems) return formattedAuthorizations;

                    // If any item is subscription, it's a subscription
                    const type = items.some(
                        ({ type }) => type === ItemCategoryType.Subscription
                    )
                        ? CustomerAuthorizationType.Subscription
                        : items.some(
                              ({ type }) => type === ItemCategoryType.One_Time
                          )
                        ? CustomerAuthorizationType.Invoice
                        : CustomerAuthorizationType.Other;

                    // If it's Canceled, otherwise it's Active
                    const active = !AgreementCanceledStatuses.includes(
                        agreement.status
                    );

                    const canceledOn = agreement.endDate
                        ? `Canceled on ${getReadableDate(agreement.endDate)}`
                        : agreement.cancellationEffectiveDate
                        ? `Scheduled for cancelation on ${getReadableDate(
                              agreement.cancellationEffectiveDate
                          )}`
                        : ``;

                    const statusBadge = agreement.endDate ? (
                        <Badge density={Density.Default} variant="gray">
                            Canceled
                        </Badge>
                    ) : agreement.cancellationEffectiveDate ? (
                        <Badge density={Density.Default} variant="gray">
                            Cancel scheduled for{" "}
                            {getReadableDate(
                                agreement.cancellationEffectiveDate
                            )}
                        </Badge>
                    ) : null;

                    // The subscription frequency
                    // [ ] We need an algoritm for how to handle this when multiple items exist
                    const firstSubItemFrequency = items.find(
                        ({ frequency, type }) =>
                            !!frequency &&
                            type === ItemCategoryType.Subscription
                    )?.frequency;
                    const frequency = firstSubItemFrequency
                        ? describeFrequency(firstSubItemFrequency)
                        : ``;

                    // The next scheduled payment
                    const nextScheduledTx = data.transactions
                        .sort((a, b) => b.billDate - a.billDate)
                        .find(
                            ({ agreementId, status }) =>
                                agreementId === agreement.id &&
                                isTransactionUpcomingOrDue(status)
                        );
                    const nextPayment = nextScheduledTx
                        ? `Your next payment of ${
                              nextScheduledTx.usd
                                  ? toDollar(nextScheduledTx.amount)
                                  : toCoin(
                                        Number(
                                            formatUnits(
                                                nextScheduledTx.amount,
                                                token?.decimals ?? 18
                                            )
                                        )
                                    )
                          } is scheduled on ${getReadableDate(
                              nextScheduledTx.billDate
                          )}`
                        : `No payments are scheduled`;

                    // The "amount" is the next transaction, or the sum of subscription items, `null` if an item is priced in token
                    const sumOfUsdSubscriptions = items.reduce<bigint | null>(
                        (sum, { amount, type }) => {
                            if (type !== ItemCategoryType.Subscription)
                                return sum;
                            if (sum === null || amount === undefined)
                                return null;

                            return sum + BigInt(amount);
                        },
                        BigInt(0)
                    );

                    // `null` if both next transaction (or no next tx) and sum of subscriptions are priced in token
                    const amount = nextScheduledTx?.usd
                        ? BigInt(nextScheduledTx.amount)
                        : sumOfUsdSubscriptions;

                    const priceVaries =
                        sumOfUsdSubscriptions === BigInt(0)
                            ? `Price varies`
                            : ``;

                    return [
                        ...formattedAuthorizations,
                        {
                            id: agreement.id,
                            type,
                            contract: contract.address,
                            entityId: entity.entityId,
                            entityName: entity.name,
                            itemName: items.map(({ name }) => name).join(`, `),
                            amount,
                            frequency,
                            priceVaries,
                            nextPayment,
                            active,
                            canceledOn,
                            statusBadge,
                            wallet: agreement.sender.wallet as HexAddress,
                            email: agreement.sender.email,
                            token,
                            tokenSymbol: token.symbol,
                            tokenAddress: token.address,
                            networkHex: toNetworkHex(agreement.networkId),
                            networkName: firstToUpper(network.name),
                            transactions: data.transactions
                                .filter(
                                    ({ agreementId, status }) =>
                                        agreement.id === agreementId &&
                                        status === TransferStatus.Succeeded
                                )
                                .reduce<CustomerAuthorization["transactions"]>(
                                    (
                                        txs,
                                        {
                                            billDate,
                                            payment,
                                            status,
                                            amount,
                                            usd,
                                            tokenAddress,
                                            networkId,
                                            items,
                                            invoiceId,
                                        }
                                    ) => {
                                        // Payment token
                                        const txToken = data.tokens.find(
                                            ({ address: a, networkId: n }) =>
                                                a === tokenAddress &&
                                                n === networkId
                                        );

                                        if (!txToken) return txs;

                                        const amtUsd =
                                            amount && usd
                                                ? toDollar(amount)
                                                : ``;
                                        const amtToken =
                                            payment?.paidAmount &&
                                            (!isStableCoin(txToken) || !usd)
                                                ? toCoin(
                                                      formatUnits(
                                                          payment.paidAmount,
                                                          txToken.decimals
                                                      )
                                                  ) + ` ${txToken.symbol}`
                                                : ``;

                                        return [
                                            ...txs,
                                            {
                                                id:
                                                    payment?.transactionHash ||
                                                    billDate.toString(),
                                                billDate: billDate,
                                                datePaid:
                                                    payment?.processedAt ||
                                                    null,
                                                amount:
                                                    amtUsd && amtToken
                                                        ? `${amtUsd} (${amtToken})`
                                                        : amtUsd ||
                                                          amtToken ||
                                                          ``,
                                                status,
                                                txHash: payment?.transactionHash ? (
                                                    <DynamicAddressDisplay
                                                        shorten
                                                        address={
                                                            payment.transactionHash
                                                        }
                                                        networkId={toNetworkHex(
                                                            agreement.networkId
                                                        )}
                                                        inheritColor={false}
                                                    >
                                                        Paid
                                                    </DynamicAddressDisplay>
                                                ) : (
                                                    ``
                                                ),
                                                itemName:
                                                    items.join(`, `) || ``,
                                                invoiceId: invoiceId ?? ``,
                                            },
                                        ];
                                    },
                                    []
                                ),
                        },
                    ];
                },
                []
            );
    }, [
        data?.agreements,
        data?.contracts,
        data?.entities,
        data?.items,
        data?.networks,
        data?.tokens,
        data?.transactions,
    ]);

    const getAuthorization = useCallback(
        (authorizationId: string) => {
            return authorizations.find(({ id }) => authorizationId === id);
        },
        [authorizations]
    );

    const getAuthorizations = useCallback(
        (options?: { type?: CustomerAuthorizationType; entityId?: string }) => {
            return authorizations.filter(
                ({ type, entityId }) =>
                    (!options?.type || type === options.type) &&
                    (!options?.entityId || entityId === options.entityId)
            );
        },
        [authorizations]
    );

    const cancelAuthorization = async (
        authorization: CustomerAuthorization
    ) => {
        // [ ] Need an error message here? or throw?
        if (authorization.type !== CustomerAuthorizationType.Subscription)
            return;

        setIsCancelAuthorizationsLoading(true);

        // [ ] Do we have a query hook that can setup and manage loading/error, but not call immediately - then only call when requested?
        return await patchCustomerCancelAgreements(
            walletConnected.proxyFor || walletConnected.address,
            authorization.id,
            {
                "Content-Type": "application/json",
                Authorization: getSessionToken(),
                address: walletConnected.address,
            }
        ).finally(() => setIsCancelAuthorizationsLoading(false));
    };

    return {
        getAuthorizations,
        getAuthorization,
        cancelAuthorization,
        getCustomerDataRefetch,
        isLoading: isCustomerDataLoading || isCancelAuthorizationsLoading,
        isError,
    };
};

export default useAuthorizations;
