import { useCallback } from "react";

type ParentEntity = {
    parentId: string | null;
} & { [key: string]: any };

interface UseHasChildEntitiesProps {
    entityId: string;
    entities: ParentEntity[];
    isError: boolean;
    isLoading: boolean;
}

const useHasChildEntities = ({
    entityId,
    entities,
    isError,
    isLoading,
}: UseHasChildEntitiesProps) => {
    const hasChildEntities = useCallback(() => {
        const parentsDirectChildEntities = entities.filter(
            ({ parentId }: ParentEntity) => parentId === entityId
        );

        return !isError && !isLoading && parentsDirectChildEntities.length > 0;
    }, [entities, entityId, isError, isLoading]);

    return hasChildEntities;
};

export default useHasChildEntities;
