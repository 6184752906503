import * as S from "./style";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNotificationQueue } from "context/NotificationQueue";
import { Company } from "company/types";
import { SmartContractType } from "company/routes/Developer";
import BatchPayForm from "company/components/BatchPayForm";
import Anchor from "components/Anchor";
import Section from "components/Section";
import LoadingBox from "components/LoadingBox";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import { NotificationType } from "components/Notification";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { isOutboundPayment } from "utils/items";
import { nonCanceledAgreementStatusNumbers } from "types/common-enums";
import useInvalidateQuery from "hooks/useInvalidateQuery";
import { QueryKey } from "api/types";

const SchedulePayments = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const itemId = searchParams.get("itemId");
    const fromAddress = searchParams.get("fromAddress");
    const tokenAddress = searchParams.get("tokenAddress");

    const { addNotification } = useNotificationQueue();
    const { invalidateQueries } = useInvalidateQuery();

    const { tokens, getTokensMetadataIsLoading, getTokensMetadataIsError } =
        useGetTokensMetadata();

    const {
        config: { contracts, entities },
        getCompanyConfigIsError,
        getCompanyConfigIsLoading,
    } = useGetCompanyConfig();

    const { networks, getNetworksIsError, getNetworksIsLoading } =
        useGetNetworks();

    const { items, getCompanyItemsIsError, getCompanyItemsIsLoading } =
        useGetCompanyItems();
    const paymentItems = useMemo(
        () =>
            items.filter((item) => isOutboundPayment(item.type) && item.active),
        [items]
    );

    const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
        itemId || paymentItems[0]?.id
    );

    useEffect(() => {
        if (!itemId) return;
        setSelectedItemId(itemId);
    }, [itemId]);

    const {
        agreements,
        getCompanyAgreementsIsLoading,
        getCompanyAgreementsIsError,
        getCompanyAgreementsRefetch,
    } = useGetCompanyAgreements({
        statuses: nonCanceledAgreementStatusNumbers.join(`,`),
        itemIds: selectedItemId ?? `hack-to-prevent-too-many-agreements`,
    });

    const isLoading =
        getTokensMetadataIsLoading ||
        getCompanyConfigIsLoading ||
        getNetworksIsLoading ||
        getCompanyItemsIsLoading ||
        getCompanyAgreementsIsLoading;

    const isError =
        getTokensMetadataIsError ||
        getCompanyConfigIsError ||
        getNetworksIsError ||
        getCompanyItemsIsError ||
        getCompanyAgreementsIsError;

    const [formSubmittedSuccess, setFormSubmittedSuccess] =
        useState<boolean>(false);

    useEffect(() => {
        if (!formSubmittedSuccess) return;
        addNotification({
            msg: `Batch payment successfully created`,
            type: NotificationType.SUCCESS,
        });
        getCompanyAgreementsRefetch();
        invalidateQueries(QueryKey.CompanyTransfers);
        navigate("/transactions/upcoming");
    }, [
        formSubmittedSuccess,
        getCompanyAgreementsRefetch,
        invalidateQueries,
        addNotification,
        navigate,
    ]);

    // Check if variable rate contracts exist
    const hasVariableRateContracts =
        contracts.filter(
            (c: Company.Contract) =>
                c.contractType === SmartContractType.VariableRate
        ).length > 0;

    if (isError) return <FailedDataFetchingMessage />;
    if (isLoading) return <LoadingBox height="30rem" />;

    return (
        <Section>
            {hasVariableRateContracts ? (
                <BatchPayForm
                    onFormSuccess={() => setFormSubmittedSuccess(true)}
                    selectedItemId={selectedItemId}
                    setSelectedItemId={setSelectedItemId}
                    selectedFromAddress={fromAddress || undefined}
                    selectedTokenAddress={tokenAddress || undefined}
                    tokens={tokens}
                    contracts={contracts}
                    networks={networks}
                    paymentItems={paymentItems}
                    agreements={agreements}
                    entities={entities}
                />
            ) : (
                <S.Paragraph>
                    Loop's Payment requests require both an{" "}
                    <Anchor
                        href={
                            import.meta.env.VITE_LOOP_DOCS_API_ENABLED_CONTRACT
                        }
                    >
                        API-Enabled Contract
                    </Anchor>{" "}
                    and a signed message with the{" "}
                    <Anchor
                        href={
                            import.meta.env
                                .VITE_LOOP_DOCS_SECURING_WITH_SIGNATURES
                        }
                    >
                        Signer Wallet
                    </Anchor>
                    .
                </S.Paragraph>
            )}
        </Section>
    );
};

export default SchedulePayments;
