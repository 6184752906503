import bs58 from "bs58";
import { getAddress, isAddress } from "ethers";
import { PublicKey } from "@solana/web3.js";
import { clusterToNetworkId } from "utils/solana";
import { regex } from "utils/regex";

export const isValidHex = (value: string): value is HexAddress =>
    /^0x[0-9a-fA-F]+$/.test(value);

// [ ] Expand this to accept BigInt, possibly replace all together with toNetworkId
export const toNetworkHex = (value: number): HexAddress =>
    `0x${value.toString(16)}`;

export const toNetworkId = (value: string | number): number => {
    if (typeof value === `string` && value.startsWith("0x"))
        return parseInt(value, 16);
    else if (typeof value === `string`) return parseInt(value);

    return value;
};

export const networkToHex = (value: string | number): HexAddress => {
    if (typeof value === "number") return toNetworkHex(value);

    const numericValue = Number(value);
    if (!isNaN(numericValue)) return toNetworkHex(numericValue);

    const solanaNetwork = clusterToNetworkId(value);
    if (solanaNetwork) return solanaNetwork;

    if (isValidHex(value)) return value;
    throw new Error(`Cannot convert ${value} to a network hex address.`);
};

export const getEvmAddress = (address: string): string => {
    try {
        return getAddress(address.toLowerCase());
    } catch (error) {
        console.error(error);
        return address;
    }
};

export const isSolTxHash = (hash: string): boolean => {
    try {
        return bs58.decode(hash).length === 64;
    } catch (e) {
        return false;
    }
};

export const isSolAddress = (addr: string): boolean => {
    try {
        return PublicKey.isOnCurve(new PublicKey(addr));
    } catch (e) {
        return false;
    }
};

export const isEvmTxHash = (hash: string): boolean => {
    const evmTxFormat = /^0x[a-fA-F0-9]{64}$/;
    return evmTxFormat.test(hash);
};

export const isEvmAddress = (addr: string): boolean => {
    return isAddress(addr);
};

export const shortenAddress = (addr: string) => {
    if (isSolAddress(addr) || isSolTxHash(addr))
        return addr.slice(0, 4).concat("...").concat(addr.slice(-4));

    // The check to ensure ENS names don't get shortened
    if (isDomain(addr) || (!isEvmAddress(addr) && !isEvmTxHash(addr)))
        return addr;

    return addr.slice(0, 6).concat("...").concat(addr.slice(-4));
};

export const isDomain = (domain: string): boolean => {
    return regex.domain.test(domain);
};
