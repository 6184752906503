import * as S from "./style";
import { HTMLAttributes, ReactNode } from "react";
import { SpacingTrack } from "theme/spacing";
import Title from "components/Title";

interface SectionProps extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
    children: ReactNode;
    narrow?: boolean;
    title?: ReactNode;
    spacing?: SpacingTrack;
}

const Section = ({
    children,
    narrow = false,
    title,
    spacing,
    ...props
}: SectionProps) => {
    return (
        <S.Section $narrow={narrow} spacing={spacing} {...props}>
            {title && (
                <S.TitleWrapper>
                    <Title level="h2" bold={false}>
                        {title}
                    </Title>
                </S.TitleWrapper>
            )}
            {children}
        </S.Section>
    );
};

export default Section;
