import styled from "styled-components";
import { Button } from "components/Button/style";
import { Select } from "components/Select/styles";

export const AuthorizedNewWalletForm = styled.div`
    display: inline-flex;
    align-items: center;

    ${Select} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-inline-end: 1.5rem;
    }

    ${Select} + ${Button} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
        white-space: nowrap;
    }
`;
