import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { extractPartials, uniqueByKey } from "utils/arrays";
import {
    CustomerAuthorization,
    CustomerAuthorizationType,
} from "customer/types";
import useAuthorizations from "customer/hooks/useAuthorizations";
import { MenuItem } from "components/MainMenu";
import Loading from "components/Loading";
import Anchor from "components/Anchor";
import ErrorMessage from "components/ErrorMessage";
import { Spacing } from "theme/spacing";

interface CustomerProps {
    children: ReactNode;
}

interface CustomerValue {
    merchantMenuItems: MenuItem[];
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerContext = createContext<CustomerValue>({} as CustomerValue);

const CustomerProvider = ({ children, ...props }: CustomerProps) => {
    const {
        getAuthorizations,
        isLoading: isAuthorizationsLoading,
        isError,
    } = useAuthorizations();

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(isAuthorizationsLoading);
    }, [isAuthorizationsLoading]);

    const merchantMenuItems = useMemo(() => {
        const partialAuthorizations = extractPartials(
            getAuthorizations(),
            () => true,
            [`entityName`, `entityId`]
        ).filter(
            uniqueByKey("entityId") as (
                obj: Partial<CustomerAuthorization>
            ) => boolean
        );

        return partialAuthorizations.map(({ entityId, entityName }) => {
            // Create the initial object structure
            const authorizationObject = {
                path: `/${entityId}`,
                label: entityName ?? ``,
                items: getAuthorizations({
                    type: CustomerAuthorizationType.Subscription,
                    entityId,
                }).map((auth) => ({
                    path: `/${auth.id}`,
                    label: auth.itemName,
                    items: [],
                })),
            };
            return authorizationObject;
        });
    }, [getAuthorizations]);

    const value: CustomerValue = useMemo(() => {
        return { merchantMenuItems, isLoading, setIsLoading };
    }, [merchantMenuItems, isLoading]);

    return (
        <CustomerContext.Provider value={value} {...props}>
            {isLoading && <Loading />}
            {isError ? (
                <ErrorMessage
                    msg="Failed to fetch the data"
                    spacing={[Spacing.lg]}
                >
                    If this keeps happening please{" "}
                    <Anchor href={`mailto:${import.meta.env.VITE_EMAIL_HELP}`}>
                        contact us
                    </Anchor>
                    .
                </ErrorMessage>
            ) : (
                children
            )}
        </CustomerContext.Provider>
    );
};

const useCustomer = (): CustomerValue => {
    const context = useContext<CustomerValue>(CustomerContext);
    if (context === undefined) {
        throw new Error(`useCustomer() must be used within a CustomerProvider`);
    }
    return context;
};

export { CustomerProvider, useCustomer };
