import styled, { css } from "styled-components";
import { Label } from "components/Label/styles";
import { Hint } from "components/Hint/styles";
import spacing, { SpacingTrack } from "theme/spacing";
import { withSpacing } from "utils/styling";

export const Field = styled.fieldset<{
    disabled: boolean;
    spacing?: SpacingTrack;
}>`
    ${({ spacing }) => withSpacing(spacing)}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
        `}

    ${Label} {
        margin-bottom: 0.7em;
    }
    ${Hint} {
        margin-top: ${spacing.xxxxs};
    }
`;
