import { EnsRecord } from "context/EnsProvider";
import { useState, useEffect } from "react";

export interface EnsAddressProps extends Pick<EnsRecord, "address"> {
    ensName: Promise<string | null> | undefined;
    loading?: string; // Shown when ens check is not yet complete (may consider making this a ReactNode in future)
}

export const useEnsOrAddress = ({
    address,
    ensName,
    loading = address,
}: EnsAddressProps) => {
    const [addressOrEns, setAddressOrEns] = useState<string | null>(loading);

    useEffect(() => {
        if (!ensName) return;
        ensName.then(
            (n: string | null) => {
                setAddressOrEns(n);
            },
            (e: any) => {
                setAddressOrEns(address);
            }
        );
    }, [address, ensName]);

    return { addressOrEns };
};
