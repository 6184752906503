import styled, { css } from "styled-components";
import { colorsDeprecated as c } from "global-style";
import { TitleLevel } from ".";
import { fontSizes, fontWeight } from "theme/typography";
import { SpacingTrack } from "theme/spacing";
import { withSpacing } from "utils/styling";

const fontSizeForTitle = {
    h1: fontSizes.xxl,
    h2: fontSizes.xl,
    h3: fontSizes.ml,
    h4: fontSizes.md,
    h5: fontSizes.sm,
    h6: fontSizes.xs,
};

export const Title = styled.h1.withConfig({
    shouldForwardProp: (prop) =>
        !["wrap", "size", "spacing", "lineUnder", "uppercase", "bold"].includes(
            prop
        ),
})<{
    size: TitleLevel;
    bold: boolean;
    spacing?: SpacingTrack;
    wrap?: boolean;
    lineUnder?: boolean;
    uppercase?: boolean;
}>`
    font-size: ${({ size }) => fontSizeForTitle[size]};
    font-weight: ${({ bold }) => (bold ? fontWeight.bold : fontWeight.normal)};
    margin: 0;
    ${({ spacing }) => withSpacing(spacing)}
    ${({ wrap }) => {
        if (!wrap) {
            return css`
                white-space: nowrap;
                overflow-x: clip;
                text-overflow: ellipsis;
            `;
        }
    }}
    ${({ lineUnder }) => {
        if (lineUnder) {
            return css`
                border-block-end: 1px solid ${c.border};
            `;
        }
    }}
    ${({ uppercase }) => {
        if (uppercase) {
            return css`
                text-transform: uppercase;
            `;
        }
    }}
`;
