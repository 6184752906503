import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "api/types";

const useInvalidateQuery = () => {
    const queryClient = useQueryClient();

    // It could be smarter, see the documentation on invalidating cache queries:
    // https://tanstack.com/query/v5/docs/framework/react/guides/query-invalidation#query-matching-with-invalidatequeries
    const invalidateQueries = useCallback(
        async (queryKey: QueryKey, options?: Record<string, any>) => {
            await queryClient.invalidateQueries({
                queryKey: [queryKey, { ...options }],
            });
        },
        [queryClient]
    );

    return {
        invalidateQueries,
    };
};

export default useInvalidateQuery;
