import * as S from "./style";
import {
    ReactNode,
    CSSProperties,
    MouseEventHandler,
    useState,
    useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { isAbsolutePath } from "utils/urls";
import { getBooleanFromFnOrBool } from "utils/booleans";
import { HasRoleFunction, UserRole } from "context/User";

export enum MenuType {
    Main,
    Bottom,
}

export interface MenuData {
    id: string;
    heading?: string | ReactNode;
    type: MenuType;
    style?: CSSProperties;
    path?: string;
    isAvailable?: boolean | (() => boolean);
    displayOnlyForRoles?: UserRole[];
    disableUnlessWithinRoles?: UserRole[];
    items: MenuItem[];
}

/* ## USAGE NOTES: 
- `displayOnlyForRoles` and `disableUnlessWithinRoles` are for UserRole conditional checks
- `isAvailable` is for runtime functions that can check misc properties, like "does this entity have children entities?" 

Most importantly: All will assume to be displayed/enabled/available by default, if not specified */
export interface MenuItem {
    path: string;
    icon?: ReactNode;
    label: string;
    isAvailable?: boolean | (() => boolean);
    displayOnlyForRoles?: UserRole[];
    disableUnlessWithinRoles?: UserRole[];
    inheritsParentPath?: boolean;
    items?: MenuItem[];
}

interface MenuProps {
    data: MenuData;
    tabIndex?: number;
    onStaticLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    onInternalLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    checkUsersRole?: false | HasRoleFunction;
    theme?: string;
}

interface NestedMenuProps {
    nestedMenu: boolean;
    disableMenu: boolean;
    items: MenuItem[];
    menuId: string;
    parentPath?: string;
    tabIndex?: number;
    onStaticLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    onInternalLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    checkUsersRole?: false | HasRoleFunction;
}

const Menu = ({
    nestedMenu,
    disableMenu,
    items,
    menuId,
    parentPath = ``,
    tabIndex,
    onStaticLinkClick,
    onInternalLinkClick,
    checkUsersRole,
}: NestedMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const menuItems = useMemo(() => {
        const parentPathMatchesLocation =
            location.pathname.startsWith(parentPath);
        let itemPathMatchesLocation = false;
        const itemsToDisplay = items
            .filter(
                ({ isAvailable, displayOnlyForRoles }: MenuItem) =>
                    getBooleanFromFnOrBool(isAvailable) &&
                    // If there are no roles or way to check them, or they are present and the user has them
                    (!checkUsersRole ||
                        !displayOnlyForRoles ||
                        checkUsersRole(displayOnlyForRoles))
            )
            .map((item: MenuItem) => {
                // If there was a need to dynamically update the menu items based on changing
                // parameters, this could be build into a component where the state is stored
                // and returns the component when available (rather than pre-filtering)

                const fullPath = `${
                    item.inheritsParentPath ||
                    item.inheritsParentPath === undefined
                        ? parentPath.endsWith(`/`)
                            ? parentPath.slice(0, -1)
                            : parentPath
                        : ``
                }${
                    item.path?.startsWith(`/`) || isAbsolutePath(item.path)
                        ? item.path
                        : `/${item.path}`
                }`;

                // One of the subitems is open, so keep the parent open (don't think this will nest multiple layers deep)
                if (location.pathname.startsWith(fullPath)) {
                    itemPathMatchesLocation = true;
                }

                const subMenus = item.items && item.items.length > 0 && (
                    <Menu
                        nestedMenu={true}
                        disableMenu={disableMenu}
                        items={item.items}
                        menuId={fullPath}
                        parentPath={fullPath}
                        tabIndex={tabIndex}
                        onStaticLinkClick={onStaticLinkClick}
                        onInternalLinkClick={onInternalLinkClick}
                        checkUsersRole={checkUsersRole}
                    />
                );

                const disabledItem: boolean =
                    disableMenu ||
                    (checkUsersRole &&
                        item.disableUnlessWithinRoles &&
                        !checkUsersRole(item.disableUnlessWithinRoles)) ||
                    false;

                return (
                    <S.Item key={`${menuId}-${fullPath}-${item.label}`}>
                        {isAbsolutePath(fullPath) ? (
                            <S.StaticLink
                                href={fullPath}
                                onClick={
                                    !disabledItem
                                        ? onStaticLinkClick
                                        : undefined
                                }
                                tabIndex={tabIndex}
                                disabled={disabledItem}
                            >
                                <S.CropText>
                                    {item.icon}
                                    {item.label}
                                </S.CropText>
                            </S.StaticLink>
                        ) : fullPath ? (
                            <>
                                <S.InternalLink
                                    to={fullPath}
                                    onClick={
                                        !disabledItem
                                            ? onInternalLinkClick
                                            : undefined
                                    }
                                    tabIndex={tabIndex}
                                    disabled={disabledItem}
                                >
                                    <S.CropText>
                                        {item.icon}
                                        {item.label}
                                    </S.CropText>
                                </S.InternalLink>
                                {subMenus || ``}
                            </>
                        ) : (
                            <></>
                        )}
                    </S.Item>
                );
            });
        setIsOpen(parentPathMatchesLocation || itemPathMatchesLocation);
        return itemsToDisplay;
    }, [
        location.pathname,
        items,
        disableMenu,
        menuId,
        parentPath,
        tabIndex,
        onStaticLinkClick,
        onInternalLinkClick,
        checkUsersRole,
    ]);

    if (!menuItems.length) return null;

    return nestedMenu ? (
        <S.DynamicSubNav
            id={menuId}
            selected={isOpen}
            aria-expanded={isOpen}
            role="group"
        >
            {menuItems}
        </S.DynamicSubNav>
    ) : (
        menuItems
    );
};

const MainMenu = ({
    data,
    tabIndex,
    onStaticLinkClick = () => {},
    onInternalLinkClick = () => {},
    checkUsersRole = false,
    theme,
}: MenuProps) => {
    const display: boolean =
        getBooleanFromFnOrBool(data.isAvailable) &&
        // If there are no roles or way to check them, or they are present and the user has them
        (!checkUsersRole ||
            !data.displayOnlyForRoles ||
            checkUsersRole(data.displayOnlyForRoles));

    const disabledMenu: boolean =
        (checkUsersRole &&
            data.disableUnlessWithinRoles &&
            !checkUsersRole(data.disableUnlessWithinRoles)) ||
        false;

    const menuItems = display && (
        <Menu
            nestedMenu={false}
            disableMenu={disabledMenu}
            items={data.items}
            menuId={data.id}
            parentPath={data.path || ``}
            tabIndex={tabIndex}
            onStaticLinkClick={onStaticLinkClick}
            onInternalLinkClick={onInternalLinkClick}
            checkUsersRole={checkUsersRole}
        />
    );

    // [ ] Can probably nest the section within the Menu component as nestedMenu=false return
    // [ ] Does `display` need to be added to the submenu?
    return display && menuItems ? (
        <S.Menu theme={theme}>
            {data.heading && (
                <S.SectionHeading>{data.heading}</S.SectionHeading>
            )}
            <S.List id={data.id} role="group" style={data.style}>
                {menuItems}
            </S.List>
        </S.Menu>
    ) : (
        <></>
    );
};

export default MainMenu;
