import * as S from "./style";
import { ReactNode, ComponentProps } from "react";
import spacing, { Spacing } from "theme/spacing";
import SubSection from "components/SubSection";

export const SectionAndTitle = ({
    children,
    title,
    ...props
}: {
    children: ReactNode;
    title: ReactNode;
} & ComponentProps<typeof SubSection>) => (
    <S.Section
        title={title}
        titleProps={{
            lineUnder: true,
            bold: false,
            uppercase: true,
            level: `h3`,
            size: `h4`,
            spacing: [Spacing.none, Spacing.xs],
            style: {
                paddingBottom: spacing.xxs,
            },
        }}
        spacing={[Spacing.md, Spacing.xxxl]}
        {...props}
    >
        {children}
    </S.Section>
);

export default SectionAndTitle;
