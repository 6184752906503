import * as S from "./style";
import { ComponentProps, ReactNode } from "react";
import Section from "components/Section";
import Title from "components/Title";
import Paragraph from "components/Paragraph";

interface ErrorMessageProps
    extends Omit<ComponentProps<typeof Section>, "children"> {
    children?: ReactNode;
    msg?: string | JSX.Element;
}
const ErrorMessage = ({ msg = ``, children, ...props }: ErrorMessageProps) => {
    return (
        <S.ErrorMessage {...props}>
            {msg && <Title level="h2">{msg}</Title>}
            {children && <Paragraph>{children}</Paragraph>}
        </S.ErrorMessage>
    );
};

export default ErrorMessage;
