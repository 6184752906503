import * as S from "company/routes/Customers/components/CustomersTable/styles";
import Badge from "components/Badge";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
    getCompanyAgreements,
} from "api";
import { PaginatedCompanyAgreementResponse } from "api/types/company";
import CancelAccountForm from "company/components/CancelAccountForm";
import TokenTableCell from "company/components/TokenTableCell";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import {
    CompanyItemWithNumberAmount,
    useGetCompanyItems,
} from "company/hooks/useGetCompanyItems";
import {
    Company,
    CustomerHeadings,
    CustomerTableColumns,
    GetCompanyAgreementsHttpRequest,
    SortableCustomerColumns,
} from "company/types";
import { PaymentPlatformUrls } from "company/utils/entities";
import Anchor from "components/Anchor";
import BalanceAndAllowanceCell from "components/BalanceAndAllowanceCell";
import Dropdown from "components/Dropdown";
import DropdownItem from "components/DropdownItem";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import VerticalDots from "components/icons/VerticalDots";
import { useUser, UserRole } from "context/User";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import useTableData, { TableFormattingData } from "hooks/useTableData";
import { AgreementType, ItemCategoryType } from "types/common-enums";
import {
    strFrequency,
    SECONDS_IN_DAY,
    formatDateTimeFromSeconds,
} from "utils/datetime";
import { firstToUpper } from "utils/strings";
import { useModal } from "context/ModalProvider";
import { EnsRecord, useEns } from "context/EnsProvider";
import { setSortableColumns } from "utils/tables";
import { QueryKey } from "api/types";

const headings: RowHeading<CustomerHeadings>[] = [
    { label: "Sender", field: CustomerTableColumns.sender },
    { label: "Item", field: CustomerTableColumns.item },
    { label: "Subscription ID", field: CustomerTableColumns.externalId },
    { label: "Start Date", field: CustomerTableColumns.startDate },
    { label: "Token", field: CustomerTableColumns.token },
    { label: "Status", field: CustomerTableColumns.allowanceAndBalance },
    {
        label: "Manage",
        field: CustomerTableColumns.manage,
        style: { shrink: true, textAlign: "center" },
    },
];

export interface FormattingData {
    canManage: boolean;
    entities: Company.Entity[];
    networks: CommonBlockchainNetworkResponse[];
    tokens: GeneralTokenDetailsResponse[];
    items: CompanyItemWithNumberAmount[];
    openModal: (modal: React.ReactNode, title: string) => void;
    closeModal: () => void;
    getEnsRecord: (address: string) => EnsRecord | undefined;
}

const formatAgreement = ({
    response,
    formattingData: {
        canManage,
        entities,
        networks,
        tokens,
        items,
        openModal,
        closeModal,
        getEnsRecord,
    },
}: TableFormattingData<
    PaginatedCompanyAgreementResponse | undefined,
    FormattingData
>) => {
    const { agreements = [], totalResults = 0 } = response || {};

    const records: Tuple[] = agreements.map((agreement) => {
        const entity = entities.find(
            (entity) => entity.entityId === agreement.entity
        );

        const paymentPlatformUrls = new PaymentPlatformUrls(
            entity?.paymentPlatformProvider,
            entity?.externalSite
        );

        const network = networks.find(
            (network) => network.id === agreement.networkId
        );

        const token = tokens.find(
            (token) =>
                token.address === agreement.token &&
                token.networkId === agreement.networkId
        );
        const tokenNetwork =
            token && networks.find((network) => network.id === token.networkId);

        const agreementItems = items.filter((item) =>
            agreement.items.includes(item.id)
        );

        const agreementCanceled =
            agreement.status === AgreementType[AgreementType.Canceled];

        const agreementScheduleCancel =
            agreement.status === AgreementType[AgreementType.Scheduled_Cancel];

        const canCancelAgreement =
            canManage &&
            !agreementCanceled &&
            agreementItems.every(
                (item) => item.type !== ItemCategoryType.One_Time
            );

        // ! RP: This looks sketchy to me - this should be handled better
        if (!token || !network || !agreementItems.length || !tokenNetwork) {
            return { id: agreement.id, values: [] };
        }

        const itemsList = (
            <S.ItemList>
                {agreementItems.map((item) => (
                    <li key={item.id}>
                        <b>{item.name}</b>{" "}
                        <em>
                            {item.frequency?.value ||
                            item.frequency?.value === 0
                                ? strFrequency(
                                      item.frequency.value,
                                      item.frequency.type
                                  )
                                : ""}
                        </em>
                    </li>
                ))}
            </S.ItemList>
        );

        const itemNames = agreementItems.map((item) => item.name).join(", ");

        const createdAtDateTime = new Date(agreement.createdAt * 1000);

        const showNewBadge =
            agreement.createdAt > Date.now() / 1000 - SECONDS_IN_DAY * 2 &&
            !agreementCanceled;

        const senderEnsName = getEnsRecord(agreement.sender.wallet)?.name;

        const handleCancelAccountFormModal = () => {
            if (!token) return;
            openModal(
                <CancelAccountForm
                    onClose={closeModal}
                    onComplete={closeModal}
                    agreementId={agreement.id}
                    createdAt={agreement.createdAt}
                    senderWallet={agreement.sender.wallet}
                    senderEmail={agreement.sender.email}
                    items={agreementItems}
                    token={token}
                    network={network}
                />,
                `Cancel Agreement`
            );
        };

        const hasSubscription = agreementItems.some(
            (item) => item.type === ItemCategoryType.Subscription
        );
        const subscriptionLink =
            agreement.externalId && hasSubscription ? (
                <Anchor
                    href={paymentPlatformUrls.subscriptionUrl(
                        agreement.externalId
                    )}
                    target="_blank"
                >
                    {agreement.externalId}
                </Anchor>
            ) : (
                `-`
            );

        const tableColumnValues: Field[] = [
            {
                label: (
                    <S.FlexContainer>
                        <div>
                            <DynamicWalletAddressDisplay
                                address={agreement.sender.wallet}
                                ensName={senderEnsName}
                                networkId={network?.hexId}
                                shorten
                                icon
                                iconFill="currentColor"
                            />
                            <p>{agreement.sender.email}</p>
                        </div>
                        {showNewBadge && <Badge variant="green">New</Badge>}
                    </S.FlexContainer>
                ),
                value: `${agreement.sender.wallet}, ${agreement.sender.email}`,
                text: `${agreement.sender.wallet}, ${agreement.sender.email}`,
            },
            {
                label: itemsList,
                value: itemNames,
                text: itemNames,
            },
            {
                label: subscriptionLink,
                value: agreement.externalId || `-`,
                text: agreement.externalId || `-`,
            },
            {
                label: formatDateTimeFromSeconds(agreement.createdAt),
                value: createdAtDateTime.getTime(),
                text: formatDateTimeFromSeconds(agreement.createdAt),
            },
            {
                label: <TokenTableCell token={token} />,
                value: `${token.name} (${firstToUpper(tokenNetwork.name)})`,
                text: token.name,
            },
            {
                label: (
                    <BalanceAndAllowanceCell
                        token={token}
                        walletAddress={agreement.sender.wallet}
                    />
                ),
                value: "Get Data",
                text: "Get Data",
            },
        ];

        if (canManage) {
            tableColumnValues.push({
                label: (
                    <>
                        {agreementCanceled ? (
                            <S.CanceledDate>
                                Canceled:
                                <br />
                                {formatDateTimeFromSeconds(agreement.endDate)}
                            </S.CanceledDate>
                        ) : agreementScheduleCancel &&
                          agreement.cancellationEffectiveDate ? (
                            <S.CanceledDate>
                                Scheduled Cancel:
                                <br />
                                {formatDateTimeFromSeconds(
                                    agreement.cancellationEffectiveDate
                                )}
                            </S.CanceledDate>
                        ) : canCancelAgreement ? (
                            <Dropdown
                                anchorEl={
                                    <a
                                        href="#menu"
                                        onClick={(event) =>
                                            event.preventDefault()
                                        }
                                    >
                                        <VerticalDots
                                            width="1rem"
                                            height="1rem"
                                            fill="#888"
                                        />
                                    </a>
                                }
                            >
                                {canCancelAgreement && (
                                    <DropdownItem
                                        onClick={handleCancelAccountFormModal}
                                    >
                                        Cancel
                                    </DropdownItem>
                                )}
                            </Dropdown>
                        ) : (
                            <></>
                        )}
                    </>
                ),
                value: "Manage",
                text: "Manage",
                style: { textAlign: "center" },
            });
        }

        return {
            id: agreement.id,
            values: tableColumnValues,
        };
    });

    return { response, totalResults, records };
};

// export interface CustomerFilters {}

interface UseCustomerTableProps {
    initialFilters?: GetCompanyAgreementsHttpRequest;
    initialSort?: SortBy;
    initialPagination?: Pagination;
}

export const useCustomerTable = (config: UseCustomerTableProps) => {
    const { hasRole } = useUser();
    const canManage = hasRole(UserRole.COMPANY);
    const { openModal, closeModal } = useModal();
    const { getEnsRecord } = useEns();

    // Removed managed column if not applicable, then set sortability for columns
    const headingsManagedAndSorted = setSortableColumns(
        canManage ? headings : headings.slice(0, -1),
        SortableCustomerColumns
    );

    const {
        config: { entities },
        getCompanyConfigIsLoading,
    } = useGetCompanyConfig();

    const { networks, getNetworksIsLoading } = useGetNetworks();

    const { tokens, getTokensMetadataIsLoading } = useGetTokensMetadata();
    useGetTokensMetadata();

    const { items, getCompanyItemsIsLoading } = useGetCompanyItems();

    const tableData = useTableData<
        PaginatedCompanyAgreementResponse | undefined,
        GetCompanyAgreementsHttpRequest,
        CustomerHeadings,
        FormattingData
    >({
        queryKey: QueryKey.CompanyAgreements,
        apiQueryFn: getCompanyAgreements,
        headings: headingsManagedAndSorted,
        formatFn: formatAgreement,
        formattingData: {
            canManage,
            entities,
            networks,
            tokens,
            items,
            openModal,
            closeModal,
            getEnsRecord,
        },
        ...config,
    });

    return {
        ...tableData,
        isLoading:
            getCompanyConfigIsLoading ||
            getNetworksIsLoading ||
            getTokensMetadataIsLoading ||
            getCompanyItemsIsLoading ||
            tableData.isLoading,
    };
};

export default useCustomerTable;
