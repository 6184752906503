import * as S from "./style";
import { ComponentProps, HTMLAttributes, ReactNode } from "react";
import { SpacingTrack } from "theme/spacing";
import Title from "components/Title";
import Paragraph from "components/Paragraph";

export enum Separator {
    Top = "top",
    Bottom = "bottom",
    Both = "both",
}

interface SubSectionProps
    extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
    children?: ReactNode;
    title?: ReactNode;
    description?: ReactNode;
    spacing?: SpacingTrack;
    separator?: Separator | `${Separator}`;
    titleProps?: ComponentProps<typeof Title>;
}

// [ ] How is this specifically different from Section? This should be addressed
const SubSection = ({
    children,
    title,
    titleProps,
    description,
    spacing,
    separator,
}: SubSectionProps) => {
    return (
        <S.SubSection spacing={spacing} separator={separator}>
            {(title || description) && (
                <S.TitleWrapper>
                    {title && (
                        <Title {...titleProps} level="h3">
                            {title}
                        </Title>
                    )}
                    {description && <Paragraph>{description}</Paragraph>}
                </S.TitleWrapper>
            )}
            {children}
        </S.SubSection>
    );
};

export default SubSection;
