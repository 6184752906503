import { useState } from "react";
import { apiServerUrl } from "default-variables";
import { useNotificationQueue } from "context/NotificationQueue";
import { useSession } from "context/Session";
import { regex } from "utils/regex";
import Loading from "components/Loading";
import { NotificationType } from "components/Notification";
import { getErrorMessage } from "utils/errors";
import Title from "components/Title";
import { Spacing } from "theme/spacing";
import Form from "components/Form";
import Field from "components/Field";
import Input, { InputSizes } from "components/Input";
import Hint, { HintType } from "components/Hint";
import Button, { ButtonSizes } from "components/Button";

const LoginForm = () => {
    const { addNotification } = useNotificationQueue();
    const { setWeb2Session } = useSession();

    const [state, setState] = useState({
        email: { value: ``, error: false },
        password: { value: ``, error: false },
        loading: false,
    });

    const { email, password, loading } = state;

    const handleEmailChange = (evt: any) => {
        setState({
            ...state,
            email: { value: evt.target.value, error: false },
        });
    };

    const handlePasswordChange = (evt: any) => {
        setState({
            ...state,
            password: { value: evt.target.value, error: false },
        });
    };
    const handleSubmit = async (evt: any) => {
        evt.preventDefault();
        // This prevents the form from submitting without valid credentials
        // Replaced this when MUI is in or out

        const valid = { email: true, password: true };
        if (!email.value.match(regex.email)) {
            valid.email = false;
        }
        if (!password.value.match(regex.password)) {
            valid.password = false;
        }
        setState({
            email: { ...email, error: !valid.email },
            password: { ...password, error: !valid.password },
            loading: valid.email && valid.password, // If both are valid, it's time to start loading data
        });
        if (!valid.email || !valid.password) return; // [ ] May have to remove `.email` from this and just check `!valid`

        let res, data;
        try {
            res = await fetch(`${apiServerUrl}/v1/user/login`, {
                method: `POST`,
                body: JSON.stringify({
                    email: email.value.trim(),
                    password: password.value,
                }),
            });
            data = await res.json();

            if (!res.ok) {
                throw await data;
            }
        } catch (error: unknown) {
            const errorMsg = getErrorMessage(error);
            addNotification({ msg: errorMsg, type: NotificationType.ERROR });
            return;
        } finally {
            setState({
                loading: false,
                email: { ...email, error: false },
                password: { ...password, error: false },
            });
        }

        // if successful, create cookie and redirect to dashboard
        const { token, entityId, companyName, roles, delegatedSigning }: any =
            data;
        if (token && entityId) {
            setWeb2Session(
                token,
                entityId,
                companyName,
                roles,
                delegatedSigning
            );
        } else {
            addNotification({
                msg: `Something went wrong`,
                type: NotificationType.ERROR,
            });
        }
    };

    return (
        <>
            {state.loading && <Loading />}
            <Form onSubmit={handleSubmit}>
                <Title level="h2" spacing={[Spacing.sm]}>
                    Admin Dashboard
                </Title>

                <Field>
                    <Input
                        placeholder="Email"
                        inputSize={InputSizes.Large}
                        value={email.value}
                        onChange={handleEmailChange}
                        aria-invalid={email.error}
                    />
                    {email.error && (
                        <Hint type={HintType.Error}>
                            Must be a valid email address.
                        </Hint>
                    )}
                </Field>
                <Field>
                    <Input
                        placeholder="Password"
                        type="password"
                        inputSize={InputSizes.Large}
                        value={password.value}
                        onChange={handlePasswordChange}
                        aria-invalid={password.error}
                    />
                    {password.error && (
                        <Hint type={HintType.Error}>
                            Passwords are at least 8 characters in length.
                        </Hint>
                    )}
                </Field>

                <Button
                    size={ButtonSizes.Large}
                    type="submit"
                    disabled={loading}
                    full
                >
                    Login
                </Button>
            </Form>
        </>
    );
};

export default LoginForm;
