import * as S from "./style";
import { ReactNode } from "react";

interface TooltipProps {
    children: ReactNode | JSX.Element;
    title: ReactNode | JSX.Element | undefined;
    noWrap?: boolean;
    slim?: boolean;
    abbr?: boolean;
    disabled?: boolean;
    placement?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top";
}
const Tooltip = ({
    children,
    title,
    noWrap = false,
    slim = false,
    abbr = false,
    disabled = false,
    placement = `right`,
    ...props
}: TooltipProps) => {
    if (!title) return children;
    return (
        <S.OpaqueTooltip
            title={title}
            placement={placement}
            $noWrap={noWrap}
            $slim={slim}
            disableFocusListener={disabled}
            disableHoverListener={disabled}
            disableInteractive={disabled}
            disableTouchListener={disabled}
            arrow
            {...props}
        >
            <S.Prompt $abbr={abbr}>{children}</S.Prompt>
        </S.OpaqueTooltip>
    );
};

export default Tooltip;
