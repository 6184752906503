import { useState, useEffect } from "react";
import { formatUnits } from "ethers";
import { CustomerAuthorization } from "customer/types";
import { tokenToCents } from "utils/exchangeRates";
import { useWalletConnected } from "context/Wallet/WalletConnected";

const useTokenAllowance = (
    authorization: CustomerAuthorization | undefined
) => {
    const [allowance, setAllowance] = useState<{
        allowanceCoin: string | null;
        allowanceUsd: string | null;
    }>({ allowanceCoin: null, allowanceUsd: null });
    const { getTokenAllowance } = useWalletConnected();

    useEffect(() => {
        const fetchAllowance = async () => {
            if (!authorization || !authorization.token) {
                setAllowance({ allowanceCoin: null, allowanceUsd: null });
                return;
            }

            const result = await getTokenAllowance({
                tokenAddress: authorization.tokenAddress,
                walletAddress: authorization.wallet,
                networkId: authorization.networkHex,
                contractAddress: authorization.contract,
                force: true,
            });
            const allowanceCoin = formatUnits(
                result,
                authorization.token.decimals
            );
            const allowanceUsd = tokenToCents(
                allowanceCoin,
                authorization.token.exchange.rate
            );

            setAllowance({ allowanceCoin, allowanceUsd });
        };

        fetchAllowance();
    }, [authorization, authorization?.token, getTokenAllowance]);

    return allowance;
};

export default useTokenAllowance;
